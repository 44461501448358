<template>
    <div>
        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <div class="row">
                <div class="col-lg-3">
                    <base-input alternative=""
                        label="RRR"
                        input-classes="form-control-alternative"
                        v-model="rrr"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="School's Name"
                        placeholder="Enter School's Name"
                        input-classes="form-control-alternative"
                        v-model="record.name"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="School's Email Address"
                        placeholder="Enter Email Address"
                        input-classes="form-control-alternative"
                        v-model="record.email"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="School's Phone Number"
                        placeholder="Phone Number"
                        input-classes="form-control-alternative"
                        required
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel.phone"
                            @update="updatePhoneNumber"
                            :default-country-code="tel.code"
                            :no-example="true"
                            :clearable="true"
                        />
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="School's Address (Without State)"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter School's Address"
                            v-model="record.location"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Year of establishment of School"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.establishment"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option
                                v-for="year in  years"
                                :key="year"
                                :value="year"
                            >
                                {{ year }}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Request Type"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.request_type"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="advisory-visit">Advisory Visit</option>
                            <!-- <option value="accreditation">Accreditation</option>
                            <option value="re-accreditation">Re-accreditation</option> -->
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Type of School"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.school_type"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="private">Private School</option>
                            <option value="federal">Federal School</option>
                            <option value="state">State School</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Any other information considered necessary for the Accreditation decision"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Additional Accreditation Notes"
                            v-model="record.additional_notes"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <h6 class="heading-small mb-4">
                        Upload Files &nbsp;&nbsp;&nbsp;&nbsp;
                        <span class="text-danger">
                            <i class="fa fa-info-circle"></i> Only PDF Files (Less than 800KB) are supported
                        </span>
                    </h6>
                </div>

                <div
                    class="col-lg-4 text-center borderLine"
                    v-show="record && record.files.length > 0"
                    v-for="(item, index) in record.files"
                    :key="index"
                >
                    <br>
                    <input
                        type="file"
                        id="file" style="display: none"
                        @change="onFileSelected"
                    >
                    <h4 class="height-50">
                        {{ item.title }}
                    </h4><br>
                    <base-button
                        size="sm" type="primary"
                        @click="selectFile(index)"
                    >
                        <i class="fa fa-hand-pointer"></i>
                        {{ item.status ? 'Change File' : 'Select File' }}
                    </base-button>

                    <base-button
                        tag="a" size="sm"
                        type="success"
                        :href="item.url"
                        target="_blank"
                        role="button"
                        aria-pressed="true"
                        v-if="item.has_file"
                    >
                        <i class="fa fa-eye"></i> View File
                    </base-button>

                    <base-button
                        size="sm" type="danger"
                        @click="removeFile(index)"
                        v-if="item.status"
                    >
                        <i class="fa fa-times"></i> Remove File
                    </base-button>
                    <br><br>
                </div>

            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='(showErrorStatus || showInputErrorStatus) && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <hr>
            <base-button
                type="success"
                class="right"
                :disabled="! rrr"
                @click="savePersonalData()"
            >
                {{ isloading ? 'Please Wait...' : 'Save &amp; Continue'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
        </div>

    </div>
</template>

<script>
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';

    export default {
        name: 'ProfileData',
        props: ['locations', 'displaySummary', 'data', 'transaction', 'loadApplication', 'id'],
        components: {
            VuePhoneNumberInput
        },
        data() {
            return {
                isLoadingBlade: true,
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {
                    name: null,
                    email: null,
                    phone: null,
                    phone_code: null,
                    location: null,
                    establishment: null,
                    request_type: 'advisory-visit',
                    school_type: null,
                    additional_notes: null,
                    // file: {
                    //     has_file: false,
                    //     status: false,
                    //     name : null,
                    //     file : null
                    // },
                    files: []
                },
                selectedIndex: null,
                tel: {
                    phone: null,
                    code: 'NG',
                },
                config: {
                    allowInput: true,
                    maxDate: "today",
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'schoolFolderUrl']),
            rrr() {
                return this.transaction  ? this.transaction.payment_ref : null;
            },
            years() {
                let startYear = moment().format('YYYY') - 122;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            },
            school() {
                return this.data && this.data.training_school &&
                    this.data.training_school.school ? this.data.training_school.school : {};
            },
            hasUploadedFiles() {// check if all files has been uploaded
                let files = this.record.files;
                let records = files.filter(item => (! item.has_file && (item.file == null)))
                return records.length == 0;
            }
        },
        methods: {
            ...mapActions(['updateSchoolData']),
            setProfileData() {// set applicant data
                this.showInputErrorStatus = false;
                this.emptyFields();
                this.isLoadingBlade = false;
                if(this.data) {
                    let application = this.data;
                    let school = application.training_school;
                    this.record = {
                        name: school.name,
                        email: school.email,
                        phone: school.phone,
                        phone_code: school.phone_code,
                        location: school.location,
                        establishment: school.establishment,
                        school_type: school.school_type,
                        request_type: 'advisory-visit',
                        additional_notes: application.additional_notes,
                        // file : {
                        //     has_file: application.has_file,
                        //     status : false,
                        //     name : null,
                        //     file : null
                        // },
                        files : application.files
                    };
                    this.record.name = this.record.name ? this.record.name : this.school.school_name;
                    this.loadPhoneNumber();
                }
            },
            loadPhoneNumber() {// update phone no
                let data = this.record.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            savePersonalData() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// update
                    let data = { profile_data : this.record , id : this.id };
                    this.updateSchoolData(data).then((res) => {
                        this.loadApplication();
                        this.isloading = false;
                        if(res.status) {
                            this.displaySummary(true);
                            swal.fire("Changes Saved!", res.msg, "success");
                        } else {
                            let msg = "Error occurred while updating, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                return this.record = {
                    name: null,
                    email: null,
                    phone: null,
                    phone_code: null,
                    location: null,
                    establishment: null,
                    request_type: 'advisory-visit',
                    school_type: null,
                    additional_notes: null,
                    // file: {
                    //     has_file: false,
                    //     status: false,
                    //     name : null,
                    //     file : null
                    // },
                    files: []
                };
            },
            validatedData() {
                let data = this.record;
                this.hasUploadedFiles ? true : this.showInuptError('Files (Not Uploaded)');
                data.additional_notes != null ? data.additional_notes : this.showInuptError('Additional Accreditation Notes');
                data.school_type != null ? data.school_type : this.showInuptError('Type of School');
                data.request_type != null ? data.request_type : this.showInuptError('Request Type');
                data.establishment != null ? data.establishment : this.showInuptError('Year of Establishment');
                data.location != null ? data.location : this.showInuptError('Location of School');
                data.phone != null ? data.phone : this.showInuptError('Phone Number');
                data.name != null ? data.name : this.showInuptError('Name');
                let status = data.name && data.phone && data.location && data.establishment
                    && data.school_type && data.additional_notes && data.request_type && this.hasUploadedFiles;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            selectFile(index) {// eslint-disable-next-line
                $('#file').val('');
                this.removeFile(index);// eslint-disable-next-line
                $('#file').click();
                this.selectedIndex = index;
            },
            onFileSelected(event) {
                let index = this.selectedIndex;
                let status =  this.validateFile(event.target.files[0], index);
                if(status) {
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(event.target.files[0]);
                    fileReader.onload = (event) => {// convert file to binary
                        var file = event.target.result;
                        this.record.files[index].file = file;
                        this.record.files[index].status = true;
                        this.selectedIndex = null;
                    }
                }
                return status;
            },
            validateFile(file, index) {// check for valid file
                if(!file) { this.removeFile(index); }// update seleted file name
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 800000) {// for valid file and size
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    swal.fire("Invalid File!", "Only PDF files are accepted", "warning");
                    this.removeFile(index);
                    return false;
                } else if(fileSize > 800000) {// big file
                    swal.fire("File is too large!", "File should be less than 800KB", "warning");
                    this.removeFile(index);
                    return false;
                }
            },
            removeFile(index) {// eslint-disable-next-line
                $('#file').val('');
                this.selectedIndex = null;
                this.record.files[index].status = false;
                this.record.files[index].file = null;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setProfileData();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
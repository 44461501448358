<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>



        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-lg-12" v-if="isLoadingBlade">
                    <div class="d-flex justify-content-center">
                        <img :src="loadingBladeUrl" />
                    </div>
                </div>

                <div class="col-xl-12 order-xl-1" v-if="! isLoadingBlade">
                    <card shadow type="secondary">

                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h4 class="mb-0 text-uppercase">
                                        Application details of
                                        <em class="text-red" v-if="record">
                                            {{ record.type }}
                                        </em>
                                        Application
                                    </h4>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/applications"
                                        class="btn btn-sm btn-danger"
                                    >
                                        <i class="fa fa-reply"></i> Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="pl-lg-4">
                            <div class="container ct-example-row">
                                <div class="row">
                                    <div class="col">
                                        <ul
                                            class="list-unstyled"
                                            v-if="record && record.transaction"
                                        >
                                            <li>
                                                <h3>
                                                    Application Status: &nbsp;
                                                    <badge :type="getStatusColor(record.status)">
                                                        {{ record.status }}
                                                    </badge>
                                                </h3>
                                                <hr class="my-4">
                                                <h3>
                                                    Transaction Id: &nbsp;
                                                    <small>
                                                        {{ record.transaction && record.transaction.trans_no
                                                                ? record.transaction.trans_no : 'N/A' }}
                                                    </small>
                                                </h3>
                                                <hr class="my-4">
                                                <h3>
                                                    Transaction Amount: &nbsp;
                                                    <small>{{ record.transaction && record.transaction.amount | formatPrice }}</small>
                                                </h3>
                                                <hr class="my-4">
                                                <h3>
                                                    Transaction Date: &nbsp;
                                                    <small v-if="record.transaction">
                                                        {{ record.transaction.date_generated | getDateFormat }}
                                                    </small>
                                                </h3>
                                                <hr class="my-4">
                                                <h3 v-if="record.transaction">
                                                    Payment RRR: &nbsp;
                                                    <small>{{ record.transaction.payment_ref ? record.transaction.payment_ref : 'N/A' }}</small>
                                                </h3>
                                                <hr
                                                    class="my-4"
                                                    v-if="((record.status == 'rejected') || (record.status == 'approved'))"
                                                >
                                                <h3
                                                    v-if="((record.status == 'rejected') || (record.status == 'approved'))"
                                                >
                                                    Approved / Rejected Date: &nbsp;
                                                    <small>
                                                        {{ record.approved_date | getDateFormat }}
                                                    </small>
                                                </h3>
                                                <hr class="my-4" v-if="record.status == 'rejected'">
                                                <h3 v-if="record.status == 'rejected'">
                                                    Reject Reason: &nbsp;
                                                    <small class="text-danger">
                                                        {{ record.reject_reason ? record.reject_reason : 'N/A' }}
                                                    </small>
                                                </h3>
                                                <hr class="my-4">
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <router-link
                                    :to="'/pending-application/'+record.type+'/'+record.id"
                                    class="btn btn-lg btn-info"
                                    v-if="enableEdit"
                                >
                                    <i class="fa fa-pen"></i> {{ isPending }} Application
                                </router-link>
                            </div>

                            <div class="col-sm-12 text-center">
                                <base-alert :type="errorType" v-if='showErrorStatus'>
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{ errorMsg }}
                                    </span>
                                </base-alert>
                            </div>

                        </div>

                    </card>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'ApplicationDeatils',
        data() {
            return {
                record: {},
                errorType: null,
                showErrorStatus: false,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true
            }
        },
        filters: {
            getDateFormat(value) {
                if (value) {
                    return moment(value).format('Do MMM, YYYY');
                } else {
                    return 'N/A';
                }
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.')
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' NGN'
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            enableEdit() {
                return this.record && ((this.record.status == 'rejected') || (this.record.status == 'pending'));
            },
            isPending() {
                return this.record && (this.record.status == 'pending') ? 'Continue' : 'Edit';
            }
        },
        methods: {
            ...mapActions(['getApplication']),
            loadAppplication() {// load insurance details
                const id = this.$route.params.id;
                this.getApplication(id).then((res) => {// get application record
                    this.isLoadingBlade = false;
                    this.record = res.application;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            getStatusColor(status) {
                if(status == 'approved') {
                    return 'success';
                } else if(status == 'pending') {
                    return 'warning';
                } else {
                    return 'danger';
                }
            }
        },
        created() {
            this.loadAppplication();// load application
        },
    }
</script>
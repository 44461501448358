<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>



        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-lg-12" v-if="isLoadingBlade">
                    <div class="d-flex justify-content-center">
                        <img :src="loadingBladeUrl" />
                    </div>
                </div>

                <div class="col-xl-12 order-xl-1" v-if="! isLoadingBlade">
                    <card shadow type="secondary">

                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h4 class="mb-0 text-uppercase">
                                        Application details of
                                        <em class="text-red" v-if="record">
                                            {{ record.year }}
                                        </em>
                                        INDEXING Year Application
                                    </h4>
                                </div>
                                <div class="col-4 text-right">
                                    <a
                                        href="javascript:void(0)"
                                        @click="$router.go(-1)"
                                        class="btn btn-lg btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i> Back
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="pl-lg-4">
                            <div class="container ct-example-row">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <ul
                                            class="list-unstyled"
                                            v-if="record"
                                        >
                                            <li>
                                                <h3>
                                                    Application Status: &nbsp;
                                                    <badge :type="getStatusColor(record.status)">
                                                        {{ record.status }}
                                                    </badge>
                                                </h3>
                                                <hr class="my-4">
                                                <h3>
                                                    Application Date: &nbsp;
                                                    <small>
                                                        {{ record.application_date | getDateFormat  }}
                                                    </small>
                                                </h3>
                                                <hr
                                                    class="my-4"
                                                    v-if="((record.status == 'rejected') || (record.status == 'approved'))"
                                                >
                                                <h3
                                                    v-if="((record.status == 'rejected') || (record.status == 'approved'))"
                                                >
                                                    Approved / Rejected Date: &nbsp;
                                                    <small>
                                                        {{ record.approved_date | getDateFormat }}
                                                    </small>
                                                </h3>
                                                <hr class="my-4" v-if="record.status == 'rejected'">
                                                <h3 v-if="record.status == 'rejected'">
                                                    Reject Reason: &nbsp;
                                                    <small>{{ record.reject_reason ? record.reject_reason : 'N/A' }}</small>
                                                </h3>

                                            </li>
                                        </ul>
                                    </div>

                                    <IndexingStudents
                                        :record="record"
                                        :students="students"
                                        :pagination="pagination"
                                        :hasPagination="hasPagination"
                                        :paginate="paginate"
                                    />
                                </div>
                            </div>

                            <div>
                                <hr class="my-4">
                                <router-link
                                    :to="'/indexing/new-indexing/'+record.year"
                                    class="btn btn-lg btn-info"
                                    v-if="enableEdit"
                                >
                                    <i class="fa fa-pen"></i> {{ isPending }} List
                                </router-link>
                            </div>

                            <div class="col-sm-12 text-center">
                                <base-alert :type="errorType" v-if='showErrorStatus'>
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{ errorMsg }}
                                    </span>
                                </base-alert>
                            </div>

                        </div>

                    </card>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment';
    import IndexingStudents from './IndexingStudents.vue';

    export default {
        name: 'ApplicationDetail',
        components: {
            IndexingStudents
        },
        data() {
            return {
                record: {},
                students: [],
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                paginatedItems: [],
                errorType: null,
                showErrorStatus: false,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true
            }
        },
        filters: {
            getDateFormat(value) {
                if (value) {
                    return moment(value).format('Do MMM, YYYY');
                } else {
                    return 'N/A';
                }
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.')
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' NGN'
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            enableEdit() {
                return this.record && ((this.record.status == 'rejected'));
            },
            isPending() {
                return this.record && (this.record.status == 'pending') ? 'Continue' : 'Edit';
            },
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            }
        },
        methods: {
            ...mapActions(['getIndexingApplications']),
            loadAppplication() {// load insurance details
                let data = { id: this.$route.params.id };
                this.getIndexingApplications(data).then((res) => {// get indexing record
                    this.isLoadingBlade = false;
                    this.setData(res.indexing);
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setData(indexing) {// set paginated function
                this.record = indexing;
                if(indexing && indexing.students) {
                    let res = indexing.students;
                    this.students = [];
                    this.paginatedItems = res;
                    this.pagination.total = this.paginatedItems.length; // get items total
                    let perPage = this.pagination.perPage; // get per page count
                    if(this.paginatedItems.length > perPage) {// paginate
                        let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                        for (let index = 1; index <= numberOfPages; index++) {
                            this.students.push(this.paginatedItems[index]);
                        }
                    } else {
                        this.students = this.paginatedItems;
                    }
                }
            },
            paginate() {// paginate function
                this.students = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.students = this.paginatedItems.slice(from, to);
            },
            getStatusColor(status) {
                if(status == 'approved') {
                    return 'success';
                } else if(status == 'submitted') {
                    return 'warning';
                } else {
                    return 'danger';
                }
            }
        },
        created() {
            this.loadAppplication();// load application
        },
    }
</script>
<template>
    <div>
        <base-header type="gradient-danger" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->



            <div class="row" v-if="! isLoadingBlade && school">
                <div class="col-xl-4 col-lg-6 text-uppercase" v-if="school.format_quota">
                    <stats-card title="Allocated Quota"
                                type="gradient-danger"
                                :sub-title="school.format_quota"
                                icon="ni ni-circle-08"
                                class="mb-4 mb-xl-0 green"
                    >
                      <template slot="footer">
                        <span class="text-success mr-2"></span>
                      </template>
                    </stats-card>
                </div>

                <div class="col-xl-4 col-lg-6 text-uppercase" v-if="school.expiry">
                    <stats-card title="Accreditation Expiration Date"
                                type="gradient-danger"
                                :sub-title="((school.request_type != 'advisory-visit') && 
                                              (school.approved_status == 'yes') ? school.expiry : 'N/A')"
                                icon="ni ni-notification-70"
                                class="mb-4 mb-xl-0 red"
                    >
                      <template slot="footer">
                        <span class="text-success mr-2"></span>
                      </template>
                    </stats-card>
                </div>

                <div class="col-xl-4 col-lg-6 text-uppercase" v-if="school.approved_status">
                    <stats-card title="Approval Status"
                                :type="'gradient-'+getColor(school.approved_status, false)"
                                :sub-title="school.approved_title.title"
                                icon="ni ni-support-16"
                                :class="'mb-4 mb-xl-0 '+getColor(school.approved_status, true)"
                    >
                      <template slot="footer">
                        <span class="text-danger mr-2">
                          {{ school.approved_title.sub_title }}
                        </span>
                      </template>
                    </stats-card>
                </div>
            </div>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7 padding30">
            <div class="row">
                <div class="col-xl-12 order-xl-2 mb-5 mb-xl-0">

                    <div class="card card-profile shadow">

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade">
                            <div class="row justify-content-center">
                              <div class="col-lg-3 order-lg-2">
                                  <div class="card-profile-image">
                                      <router-link to="/profile">
                                          <img
                                            :alt="school.name"
                                            :src="schoolFolderUrl+'/photo/'+school.photo"
                                            @error="replaceByDefault"
                                            class="rounded-circle image"
                                            v-if="school && school.photo"
                                          >
                                      </router-link>
                                  </div>
                              </div>
                          </div>
                          <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                              <div class="d-flex justify-content-between">
                                  <a href="/user-guide.pdf" target="_blank" class="mr-4 btn btn-sm btn-primary">
                                    User Guide
                                  </a>
                                  <base-button
                                    size="sm" type="default"
                                    class="float-right text-capitalize"
                                  >
                                      {{ school.type }} Training
                                  </base-button>
                              </div>
                          </div>
                          <div class="card-body pt-0 pt-md-4" v-if="school">
                              <div class="row">
                                  <div class="col">
                                      <div class="card-profile-stats d-flex justify-content-center mt-md-5">
                                      </div>
                                  </div>
                              </div>
                              <div class="text-center">
                                  <h2 class="text-capitalize">
                                      {{ school.name }}
                                  </h2>
                                  <h3 class="text-capitalize">
                                      <badge size="sm"
                                        :type="school.approved_status == 'no' ? 'warning' : 'success'"
                                      >
                                        {{ school.approved_title.sub_title }}
                                      </badge>
                                  </h3>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Expiration Date
                                        <badge size="sm" type="danger" class="text-uppercase">
                                          {{ school.expiry }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Email Address
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ school.email }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Phone Number
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ school.phone }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Year Of Establishment
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ school.establishment }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Location
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ school.location }}
                                        </badge>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
            <!-- End charts-->

            <!-- Generate RRR template here -->
            <div class="col-md-4" :show="showRejectModal">
              <modal :show="showRejectModal"
                    gradient="danger"
                    modal-classes="modal-danger modal-dialog-centered">
                  <h4
                    slot="header" class="modal-title"
                    id="modal-title-notification"
                  >
                    Importance Notice
                  </h4>

                  <div class="py-3 text-center">
                      <i class="ni ni-bell-55 ni-3x"></i>
                      <h4 class="heading mt-4">Your Application Rejected!</h4>
                      <p>
                        <strong>Note:</strong> Your Application was rejected, kindly click on the link below
                        to make corrections and submit accordingly
                      </p>
                  </div>

                  <template slot="footer">
                      <router-link
                        :to="'/application-details/' + school.app_reject_id"
                        class="btn btn-sm btn-white"
                      >
                        Check Now
                      </router-link>

                      <base-button type="link"
                                  text-color="white"
                                  class="ml-auto"
                                  @click="showRejectModal = false">
                          Close
                      </base-button>
                  </template>
              </modal>
            </div>


        </div>
    </div>
</template>
<script>
  // get user's details from store
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';


  export default {
    name: 'Dashboard',
    filters: {
        getDateFormat(value) {
            if(value) {
              return moment(value).format('Do MMM, YYYY');
            } else {
              return 'N/A';
            }
        }
    },
    computed: {
      ...mapGetters(['school', 'loadingBladeUrl', 'blankImageUrl', 'schoolFolderUrl'])
    },
    data() {
      return {
        modal: false,
        isLoadingBlade: true,
        showRejectModal: false
      };
    },
    methods: {
      ...mapActions(['fetchSchoolProfile']),
      stopLoading(res) {
        var self = this;
        setTimeout(function() { 
          self.isLoadingBlade = false; 
          self.showRejectModal = res.app_reject_id ? true : false;// show reject modal function
        }, 2000);
      },
      checkStatus() {// check for user account status
        this.fetchSchoolProfile().then((res) => {
            this.stopLoading(res);
        });
      },
      replaceString(value) {
          if(value) {
            return value.replace("-", " ");
          } else {
            return 'N/A';
          }
      },
      replaceByDefault(e) {
          return e.target.src = this.blankImageUrl;
      },
      getColor(type, status) {
        if(status) {
          return (type == 'no') ? 'red' : 'green';
        } else {
          return (type == 'no') ? 'danger' : 'success';
        }
      }
    },
    mounted() {
      this.checkStatus();// get profile
    }
  };
</script>

<style>
    .bg-default {
        background-color: #f5365c !important;
    }
    .bg-gradient-danger {
        background: linear-gradient(100deg, #f5365c 0, #96e7c4 250%) !important
         /* linear-gradient(87deg, #f7fafc 0, #96e7c4 70%) !important; */
    }
    .fill-default {
        fill: #f5365c !important;
    }
    .navbar-horizontal .navbar-brand img {
        height: 110px;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: rgb(50, 50, 93);
    }
</style>

<style scoped>
  .padding30 {
    padding: 30px;
  }
  .fontSize15 {
    font-size: 15px;
  }
  .red {
    background-color: #efd2d2;
  }
  .green {
    background-color: #bcf9c6;
  }
  .yellow {
    background-color: #e8eb4b;
  }
  .left {
    float: left;
  }
  .image {
    width: 170px;
    height: 170px;
  }
</style>

<style>
  .modal-footer {
    margin-top: -50px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1.25rem;
    border-top: 0 solid #e9ecef;
    border-bottom-right-radius: 0.4375rem;
    border-bottom-left-radius: 0.4375rem;
  }
</style>

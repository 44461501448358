<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade && ! showQualPage && ! showBulkUploadPage">
            <h3 class="text-uppercase">Academic Staff Information</h3>

            <em class="mb-4 text-danger">
                Kindly add personnels for each department below...
            </em>

            <hr class="my-4"/>

            <em class="mb-4 text-danger text-uppercase" v-if="hasRecords && this.getUnfilledDepts.length > 0">
                Kindly note that the following departments are required, but have yet to be filled. Please fill them before proceeding. <br>
                <ul>
                    <li v-for="item in this.getUnfilledDepts" :key="item">
                        {{ getDeptById(item) ? getDeptById(item).name : '' }}
                    </li>
                </ul>
            </em>

            <hr class="my-4" v-if="hasRecords && this.getUnfilledDepts.length > 0"/>

            <!-- import institution details template here -->
            <ConsultantRecords
                :consultantRecords="consultantRecords"
                :showAddRecord="showAddRecord"
                v-on:editRecord="editRecord"
                v-on:deleteRecord="deleteRecord"
                v-on:addQualification="addQualification"
                :pagination="pagination"
                :hasPagination="hasPagination"
                :paginate="paginate"
            />

            <div class="col-lg-12" v-if="! hasRecords || showAddRecord">
                <h4>
                    For Bulk Upload of Academic Staff, do the following: <br>
                    i.  Kindly click on <em class="text-primary">Sample file</em> button below to 
                    download a Sample file (Excel format). <br>
                    ii. Populate the Sample file accordingly. <br>
                    iii. Click on <em class="text-success">Upload file</em> button below to upload Staff.
                </h4>

                <span class="text-danger">
                    Note that You are to type the relative Department number not Department Name (In the Excel Sheet, 
                    when populate it), below is the Departments list with their appropriate number. <br>
                    <em class="text-primary">{{ formatDeptText }}</em>
                </span>
                <br><br>
            </div>

            <div class="row">
                <div 
                    class="col-lg-2" 
                    v-if="(! hasRecords || showAddRecord) && application && application.training_school_id"
                >
                    <form :action="getRootUrl+'/export-school-staff-sample'" method="POST">
                        
                        <input type="hidden" name="type" value="consultants" />
                        <input type="hidden" name="id" :value="application.training_school_id" />

                        <button
                            class="btn btn-lg btn-primary"
                            nativeType="submit"
                        >
                            <i class="fa fa-file-export"></i>
                            Sample file
                        </button>
                    </form>
                </div>

                <div class="col-lg-10" v-if="! hasRecords || showAddRecord">
                    <input
                        type="file"
                        ref="photo"
                        style="display: none"
                        @change="onFileSelected"
                    >

                    <button
                        class="btn btn-lg btn-success"
                        @click="$refs.photo.click()"
                    >
                        <i class="fa fa-upload"></i>
                        Upload file
                    </button>
                </div>
            </div>

            <hr class="my-4"/>

            <div class="pl-lg-4" v-if="hasRecords && ! showAddRecord">
                <base-button
                    type="primary"
                    @click="displayAddPage()"
                >
                    <i class="fa fa-plus"></i>
                    Add New Record
                </base-button>
            </div>


            <div class="row align-items-center" v-if="! hasRecords || showAddRecord">

                <div class="col-lg-12 text-center">
                    <h2 class="text-uppercase">Add an Academic Staff</h2>
                </div>
                
                <div class="col-lg-12">
                    <hr class="my-4"/>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Department"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.posting_department_id"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option
                                v-for="department in departments"
                                :value="department.id"
                                :key="department.id"
                            >
                                {{department.name}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <!-- <div class="col-lg-3">
                    <base-input alternative=""
                        label="File Number"
                        placeholder="Enter File Number"
                        input-classes="form-control-alternative"
                        v-model="record.file_no"
                        type="number"
                        required
                    />
                </div> -->

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Surname"
                        placeholder="Enter Surname"
                        input-classes="form-control-alternative"
                        v-model="record.surname"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="First Name"
                        placeholder="Enter First Name"
                        input-classes="form-control-alternative"
                        v-model="record.first_name"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Other Name"
                        placeholder="Enter Other Name"
                        input-classes="form-control-alternative"
                        v-model="record.other_name"
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Previous Name"
                        placeholder="Enter Previous Name"
                        input-classes="form-control-alternative"
                        v-model="record.previous_name"
                    />
                </div>                

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Folio Number (Numbers Only)"
                        required
                    >
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                            <div class="input-group-text" style="background: #e9ecef;">
                                MDCN/R/
                            </div>
                            </div>
                            <input 
                                type="number" 
                                class="form-control" 
                                placeholder="Enter Folio Number"
                                v-model="record.folio_number"
                                style="padding-left: 5px;"
                            />
                        </div>
                    </base-input>
                </div>
                

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="MDCN Full Registration Number"
                        placeholder="Enter Full Registration Number"
                        input-classes="form-control-alternative"
                        v-model="record.reg_no"
                        type="number"
                        required
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Registered AQ Numbers (seperate with commas)"
                        placeholder="Enter Additional Qualification Numbers"
                        input-classes="form-control-alternative"
                        v-model="record.aq_no"
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Rank in the college"
                        placeholder="Enter Rank"
                        input-classes="form-control-alternative"
                        v-model="record.rank"
                        required
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Position in the college"
                        placeholder="Enter Position"
                        input-classes="form-control-alternative"
                        v-model="record.position"
                        required
                    />
                </div>

                <div class="col-lg-12">
                    <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </base-alert>
                </div>
                <div class="col-lg-12"></div>

                <div class="col-lg-12">
                    <base-button
                        type="success"
                        @click="saveDoctorRecord()"
                    >
                        <i class="fa fa-save"></i>
                        {{isloading ? 'Please Wait...' : updateButton }}
                    </base-button>

                    <div class="right" v-if="hasRecords">
                        <base-button
                            type="danger"
                            @click="displayAddPage()"
                        >
                            <i class="fa fa-times"></i>
                            Cancel
                        </base-button>
                    </div>
                </div>

            </div>

            <hr class="my-4"/>

            <div class="pl-lg-4">
                <base-button
                    type="danger"
                    @click="showPreviousPage()"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous
                </base-button>
                <div class="right">
                    <base-button
                        type="success"
                        @click="showNextPage()"
                        :disabled="! hasAllDepts"
                    >
                        Continue
                        <i class="fa fa-arrow-right"></i>
                    </base-button>
                </div>
            </div>
        </div>

        <Qualifications
            :id="id"
            :records="consultant"
            :type="'consultant'"
            v-on:addQualification="addQualification"
            :loadApplication="loadApplication"
            v-if="showQualPage && consultant && ! showBulkUploadPage"
        />

        <ConsultantBulkUpload 
            :records="sheetRecords"
            :departments="departments"
            :isloading="isloading"
            v-on:displayUploadPage="displayUploadPage"
            v-on:uploadStaffNow="uploadStaffNow"
            v-if="showBulkUploadPage"
        />

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ConsultantRecords from './ConsultantRecords/ConsultantRecords';
    import Qualifications from './Qualifications/Qualifications';
    import ConsultantBulkUpload from './ConsultantRecords/ConsultantBulkUpload.vue'
    import swal from 'sweetalert2';// import sweet alert
    import * as XLSX from "xlsx";

    export default {
        components: {
            ConsultantRecords,
            Qualifications,
            ConsultantBulkUpload
        },
        name: 'ConsultantData',
        props: ['dept', 'loadApplication', 'application', 'id', 'displayProfileData', 'displayStaffData'],
        data() {
            return {
                consultantRecords: [],
                paginatedItems: [],
                record: {
                    posting_department_id: null,
                    file_no: null,
                    folio_number: null,
                    surname: null,
                    first_name: null,
                    other_name: null,
                    previous_name: null,
                    reg_no: null,
                    aq_no: null,
                    rank: null,
                    position: null
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                showAddRecord: false,
                newAccount: true,
                showQualPage: false,
                consultant: [],
                sheetRecords: [],
                showBulkUploadPage: false,
                allConsultants: []
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'getRootUrl']),
            formatDeptText() {
                let text = '';
                for(let dept of this.departments) {
                    text += dept.id + ' -> ' + dept.name + ', ';
                }
                return text;
            },
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            hasRecords() {
                return this.consultantRecords && this.consultantRecords.length > 0;
            },
            departments() {
                let items = [];
                let type = this.application && this.application.training_school ? this.application.training_school.type : null;
                for(let record of this.dept) {                    
                    let catArray = record.category ? Object.values(JSON.parse(record.category)) : [];// get category array
                    if((record.type == type) && catArray.includes('school')) {
                        items.push(record);
                    }
                }
                return items;
            },
            updateButton() {
                return (this.record.id ? 'Update' : 'Add') + ' Record';
            },
            getRequiredFilledDepts() {// get required depts and filled depts
                let array = [], values = [];
                for(let dept of this.departments) {// modify departments
                    let requestArray = dept.request_type ? Object.values(JSON.parse(dept.request_type)) : [];// get request type array
                    if(this.application.accredit_status && requestArray.includes(this.application.accredit_status)) {
                        values.push(dept.id);
                    }
                }
                for(let doc of this.allConsultants) {// modify doctors
                    array.push(parseInt(doc.posting_department_id));
                }
                return {filled : array, required: values };
            },
            hasAllDepts() {// if records has at least all departments
                const check = (items, wanted) => wanted.every(Set.prototype.has, new Set(items));
                let data = this.getRequiredFilledDepts; // get required depts and filled depts
                return check(data.filled, data.required);
            },
            getUnfilledDepts() {// get unfilled dept from required depts
                let data = this.getRequiredFilledDepts; // get required depts and filled depts
                return data.required.filter(x => ! data.filled.includes(x))
            }
        },
        methods: {
            ...mapActions(['updateSchoolData', 'deleteSchoolData', 'uploadBulkSchoolStaff']),
            getDeptById(id) {
                let record = this.departments.filter(e => e.id == id);
                return record && record.length > 0 ? record[0] : null;
            },
            displayAddPage() {
                this.emptyRecords();
                return this.showAddRecord = ! this.showAddRecord;
            },
            editRecord(data) {// edit record
                if(data) {
                    this.record = {
                        posting_department_id: data.posting_department_id,
                        file_no: data.file_no,
                        folio_number: data.folio_number,
                        surname: data.surname,
                        first_name: data.first_name,
                        other_name: data.other_name,
                        previous_name: data.previous_name,
                        reg_no: data.reg_no,
                        aq_no: data.aq_no,
                        rank: data.rank,
                        position: data.position,
                        id: data.id
                    };
                    this.showAddRecord = true;
                }
            },
            loadDoctorRecords() {
                this.emptyRecords();
                this.isLoadingBlade = false;
                if(this.id) {
                    this.setData(this.application);
                } else {
                    swal.fire("Session Expired!", "Session timeout, try again...", "warning");
                    window.location.href = '/';
                }
            },
            setData(application) {// set paginated function
                this.allConsultants = [];
                if(application && application.training_school) {
                    let res = application.training_school.doctors;
                    this.allConsultants = res;// store all doctors
                    this.consultantRecords = [];
                    this.paginatedItems = res;
                    this.pagination.total = this.paginatedItems.length; // get items total
                    let perPage = this.pagination.perPage; // get per page count
                    if(this.paginatedItems.length > perPage) {// paginate
                        // let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                        for (let index = 1; index <= perPage; index++) {
                            this.consultantRecords.push(this.paginatedItems[index]);
                        }
                    } else {
                        this.consultantRecords = this.paginatedItems;
                    }
                }
            },
            paginate() {// paginate function
                this.consultantRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.consultantRecords = this.paginatedItems.slice(from, to);
            },
            showPreviousPage() {// go to next page
                return this.displayProfileData(true);
            },
            showNextPage() {// go to next page
                return this.displayStaffData(true);
            },
            saveDoctorRecord() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save
                    this.preloader();// show loading
                    this.record.training_school_id = this.application.training_school.id;// add school id
                    let record = { doctor_data : this.record , id : this.id };
                    this.updateSchoolData(record).then((res) => {
                        // this.loadApplication();
                        this.isloading = false;
                        if(res.status) {
                            // this.loadDoctorRecords();// load appointments
                            this.showErrorStatus = false;
                            this.showAddRecord = false;
                            swal.fire("Record Added!", res.msg , "success");
                            this.addQualification(res.record, true);// show Doctor Qualifications                            
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { posting_department_id: null, folio_number: null, surname: null,
                                    first_name: null, reg_no: null, rank: null, position: null };
                appRecord.position = data.position == null ? this.showInuptError('Position') : data.position;
                appRecord.rank = data.rank == null ? this.showInuptError('Rank') : data.rank;
                appRecord.reg_no = data.reg_no == null ? this.showInuptError('Full Registration Number') : data.reg_no;
                appRecord.first_name = data.first_name == null ? this.showInuptError('First Name') : data.first_name;
                appRecord.surname = data.surname == null ? this.showInuptError('Surname') : data.surname;
                appRecord.folio_number  = data.folio_number == null ? this.showInuptError('Folio Number') : data.folio_number;
                // appRecord.file_no = data.file_no == null ? this.showInuptError('File Number') : data.file_no;
                appRecord.posting_department_id  = data.posting_department_id == null ? this.showInuptError('Department') : data.posting_department_id;
                return this.isEmpty(appRecord) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyRecords() {
                return this.record = {
                    posting_department_id: null,
                    file_no: null,
                    folio_number: null,
                    surname: null,
                    first_name: null,
                    other_name: null,
                    previous_name: null,
                    reg_no: null,
                    aq_no: null,
                    rank: null,
                    position: null
                };
            },
            deleteRecord(id) {// delete examination
                this.preloader();// show loading
                let data = { doctor_data : true , id : id };
                this.deleteSchoolData(data).then((res) => {
                    this.loadApplication();
                    if(res.status) {
                        swal.fire("Record Deleted!", res.msg , "success");
                        this.loadDoctorRecords(); // load appointments
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadDoctorRecords(); // load appointments
                    }
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                    this.loadDoctorRecords(); // load appointments
                });
            },
            addQualification(data, status) {
                this.consultant = [];
                if(status) {
                    this.consultant.push(data);
                    this.showQualPage = status;
                } else {
                    this.loadApplication();
                    this.showQualPage = status;
                }
            },
            displayUploadPage(status) {// show bulk upload page
                this.sheetRecords = [];
                this.showBulkUploadPage = status;
            },
            onFileSelected(event) {
                this.isLoadingBlade = true;
                this.sheetRecords = [];
                this.showBulkUploadPage = false;
                let status =  this.validateFile(event.target.files[0]);
                return status ? this.uploadExcelFile(event) : false;
            },
            uploadExcelFile(event) {
                                this.file = event.target.files[0];
                let fileReader = new FileReader();
                fileReader.readAsArrayBuffer(this.file);
                fileReader.onload = (e) => {
                    this.arrayBuffer = fileReader.result;
                    var data = new Uint8Array(this.arrayBuffer);
                    var arr = new Array();
                    for (var i = 0; i != data.length; ++i)
                    arr[i] = String.fromCharCode(data[i]);
                    var bstr = arr.join("");
                    var workbook = XLSX.read(bstr, { type: "binary" });
                    var first_sheet_name = workbook.SheetNames[0];
                    var worksheet = workbook.Sheets[first_sheet_name];
                    // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
                    this.sheetRecords = XLSX.utils.sheet_to_json(worksheet, { raw: true });
                    this.isLoadingBlade = false;
                    this.showBulkUploadPage = true;
                };
            },
            validateFile(file) {
                const validFileTypes = ['xlsx'];// valid image format array
                var fileExt = file.name.split('.').pop().toLowerCase();
                var validExt = validFileTypes.includes(fileExt);
                let fileSize = file.size;// get selected file size
                if(validExt && fileSize <= 2000000) {// for valid file and size
                    return true;
                } else if(! validExt) {// invalid file
                    swal.fire("Invalid File!", "Only Excel (xlsx) file are accepted", "warning");
                    return false;
                } else if(fileSize > 2000000) {// big file
                    swal.fire("File is too large!", "File should be less than 2MB", "warning");
                    return false;
                }
            },
            uploadStaffNow() {// upload bulk staff function
                if(this.sheetRecords && this.sheetRecords.length > 0) {// upload now
                    let data = { id : this.id, records : this.sheetRecords, type: 'consultants' };
                    this.preloader(); // show loading
                    this.uploadBulkSchoolStaff(data).then((res) => {
                        this.loadApplication();
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Record Added!", res.msg , "success");                           
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        return this.displayUploadPage(false);
                    });
                    
                } else {
                    swal.fire("Upload Failed!", "No Staff was added, try again...", "warning"); 
                    return this.displayUploadPage(false);                   
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadDoctorRecords();// load appointments
        },
    }
</script>

<style>
.right {
    float: right;
}
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>
<template>
    <div class="row align-items-center">

        <div class="col-lg-12 text-center">
            <h2 class="text-uppercase">
                Update <em class="text-danger">{{ fullName }}</em>'s Qualifications
            </h2>
        </div>

        <QualificationData 
            :records="consultant.qualifications"
            v-if="consultant.qualifications && consultant.qualifications.length > 0"
        />

        <div class="col-lg-12 text-center">
            <hr class="my-4"/>
            <h3 class="text-uppercase">Add New Qualification</h3>
            <hr class="my-4"/>
        </div>

        <div class="col-lg-6">
            <base-input alternative=""
                label="Qualification Name"
                placeholder="Enter Qualification Name"
                input-classes="form-control-alternative"
                v-model="record.name"
                required
            />
        </div>

        <div class="col-lg-6">
            <base-input
                label="Qualification Date"
                addon-left-icon="ni ni-calendar-grid-58"
                required
            >
                <flat-picker
                    slot-scope="{focus, blur}"
                    @on-open="focus"
                    @on-close="blur"
                    :config="config"
                    placeholder="Select Date.."
                    class="form-control datepicker"
                    v-model="record.qual_date"
                >
                </flat-picker>
            </base-input>
        </div>

        <div class="col-lg-12">
            <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>
        </div>

        <div class="col-lg-12">
            <base-button
                type="success"
                @click="saveQualRecord()"
            >
                <i class="fa fa-save"></i>
                {{isloading ? 'Please Wait...' : updateButton }}
            </base-button>
            <base-button
                type="danger"
                class="right"
                @click="displayQualPage(false)"
                v-if="consultant.qualifications && consultant.qualifications.length == 0"
            >
                <i class="fa fa-times"></i>
                Cancel Update
            </base-button>

            <base-button
                type="success"
                class="right"
                @click="displayQualPage(false)"
                v-if="consultant.qualifications && consultant.qualifications.length > 0"
            >
                Continue 
                <i class="fa fa-arrow-right"></i>
            </base-button>

        </div>

    </div>
</template>

<script>
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import QualificationData from './QualificationData.vue';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'UpdateQualification',
        props: ["consultant", "displayQualPage", "updateQualification"],
        components: {
            flatPicker,
            QualificationData
        },
        data() {
            return {
                qualifications: [],
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                record: {
                    name: null,
                    qual_date: null
                },
                config: {
                    maxDate: new Date().fp_incr(1),
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            }
        },
        computed: {
            fullName() {// get full name
                if(this.consultant) {
                    let name = this.consultant.first_name;
                    name += this.consultant.other_names ? ' ' + this.consultant.other_names : '';
                    name += ' ' + this.consultant.surname;
                    return name;
                } else {
                    return '';
                }
            },
            updateButton() {
                return (this.record.id ? 'Update' : 'Add') + ' Record';
            }
        },
        methods: {
            saveQualRecord() {
                let status = this.validatedInputs();// validate inputs
                if(status) {
                    this.consultant.qualifications.push(this.record);// add qualification
                    let check = this.updateQualification(this.consultant);// update record
                    let msg = check ? 'Staff\'s Qualification was updated successfully' 
                                            : 'An error occurred, try again';
                    if(check) {
                        swal.fire("Staff Updated!", msg , "success");
                        this.emptyRecords();
                    } else {
                        swal.fire("Error Occurred!", msg , "warning");
                    }
                }                
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { name: null, qual_date: null };
                appRecord.qual_date = data.qual_date == null ? this.showInuptError('Qualification Date') : data.qual_date;                
                appRecord.name  = data.name == null ? this.showInuptError('Qualification Name') : data.name     ;
                return this.isEmpty(appRecord) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            setData() {// set qualifications
                this.emptyRecords();
                this.qualifications = this.consultant.qualifications;
            },
            emptyRecords() {
                return this.record = {
                    name: null,
                    qual_date: null
                };
            }
        },
        created() {
            this.setData();
        }
    }
</script>
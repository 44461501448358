<template>
    <div class="row">
        <div
            class="col-sm-3 text-center"
            v-show="records && records.length > 0"
            v-for="record in records"
            :key="record.id"
        >
            <div class="d-flex justify-content-center">
                <img :src="certificateImageUrl" width="90px"/>
            </div>
            <br>
            <div class="d-flex justify-content-center">
                <b class="text-capitalize">
                    {{ replaceAll(record.request_type, '-', ' ') }} <br>
                    <em>
                        <small>
                            {{ AccreditStatus(record) }}
                        </small>
                    </em>
                </b>
            </div>
            <div class="d-flex justify-content-center">
                <small class="text-danger">
                    Expires on
                    {{ record.expiry_year | getDateFormat }}
                </small>
            </div>

            <div class="d-flex justify-content-center">
                <form 
                    :action="getRootUrl+'/print-school-letter'"
                    method="POST" 
                    target="_blank"
                    v-if="record && record.letter"
                >
                    <input type="hidden" name="id" :value="record.id" />
                    <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                    <input type="hidden" name="option" value="user" />
                    <input type="hidden" name="school_id" :value="school.id" />
                    <input type="hidden" name="date" :value="record.approved_date" />
                    <input type="hidden" name="ref_no" :value="record.letter.ref_no" />
                    <input type="hidden" name="accredit_status" :value="record.letter.accredit_status" />
                    <input 
                        type="hidden" 
                        name="accredit_status" 
                        :value="record.letter.accredit_status" 
                        v-if="record.request_type && (record.request_type != 'advisory-visit')"
                    />
                    <input 
                        type="hidden" 
                        name="accredit_status" 
                        :value="record.request_type" 
                        v-if="record.request_type && (record.request_type == 'advisory-visit')"
                    />
                    <input type="hidden" name="plenary" :value="record.letter.plenary" />
                    <input type="hidden" name="plenary_date" :value="record.letter.plenary_date" />
                    <input type="hidden" name="plenary_venue" :value="record.letter.plenary_venue" />
                    <input type="hidden" name="visit_date" :value="record.letter.visit_date" />
                    <input type="hidden" name="quota" :value="record.letter.quota" />

                    <base-button
                        size="sm"
                        type="success"
                        nativeType="submit"
                    >
                        <i class="fa fa-print"></i>
                        Open Document
                    </base-button>

                </form>
            </div>
            <br>
        </div>

    </div>

</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'LetterData',
        props: ['records'],
        filters: {
            getDateFormat(value) {
                return moment(value).format('Do MMM, YYYY');
            },
            getYear(value) {
                return value ? moment(value).format('YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['certificateImageUrl', 'getRootUrl', 'school', 'getAuthorizationToken'])
        },
        methods: {
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(find, 'g'), replace);
            },
            getDateFormat(value) {
                return moment(value).format('YYYY');
            },
            getLicenseYear(value) {
                if (value.certificate_type.id == 27 || value.certificate_type.id == 1) {
                    if(value.application.license_year) {
                        return '('+value.application.license_year+')';
                    } else {
                        let year = this.getDateFormat(value.application.valid_date);
                        return '('+ year +')';
                    }
                }
            },
            AccreditStatus(record) {
                return record && record.letter ? record.letter.accredit_status : 'N/A';
            },
            getExpiryDate(certificate, date) {// get expiry date
                let ids = [ 1, 8 ];// eslint-disable-next-line
                if($.inArray(certificate.id, ids) > -1) {
                    return moment(date).year()+'-12-31';
                } else {
                    return date;
                }
            }
        }
    }
</script>

<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>

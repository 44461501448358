<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                </div>
                                <!-- <div class="col-4 text-right">
                                    <a href="#!" class="btn btn-sm btn-primary">Settings</a>
                                </div> -->
                            </div>
                        </div>

                        <!-- import notification records details template here -->
                        <NotificationsData
                            v-bind:records="records"
                            v-bind:pagination="pagination"
                            v-bind:hasPagination="hasPagination"
                            v-bind:paginate="paginate"
                            :loadNotifications="loadNotifications"
                            v-if="! isLoadingBlade"
                        />

                        <div class="text-center" v-if='showErrorStatus'>
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                    </card>
                </div>

            </div>
        </div>



    </div>
</template>
<script>
    // get user's details from store
    import { mapActions, mapGetters } from 'vuex';
    import NotificationsData from './NotificationsData.vue';

    export default {
        components: {
            NotificationsData
        },
        name: 'Notifications',
        data() {
            return {
                records: [],
                paginatedItems: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    // perPage: 10  // get per page count
                    perPage: this.$store.state.url.perPage // get per page count
                },
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            },
            pageNumbers() {
                let start = 1;
                let end = this.pagination.last_page;
                let pages = [];
                for (let i = start; i <= end; i++ ) {
                    pages.push(i);
                }
                return pages;
            }
        },
        methods: {
            ...mapActions(['getSchoolNotifications', 'getPaginatedApplicationItems']),
            loadNotifications() {
                this.getSchoolNotifications(this.pagination.perPage).then((res) => {
                    this.setPages(res.notifications);
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.records = [];
                this.paginatedItems = res.data;
                this.records = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedApplicationItems(url).then((res) => {// get users
                        this.pagination.number = res.notifications.current_page;
                        this.setPages(res.notifications);
                    }).
                    catch(() => {
                        this.users = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                }
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Notifications yet!!!';
            }
        },
        created() {
            this.loadNotifications();
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 190px;
    }
</style>

<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <hr>
                <h3 class="text-uppercase text-center">
                    <em class="text-danger">{{ record.year }}</em>
                    Indexing Year's Students
                </h3>
                <hr>
            </div>

            <div class="col-lg-12" v-if="students && students.length > 0">
                <div class="card shadow">
                    <div class="table-responsive">
                        <base-table
                            class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="students"
                        >
                            <template slot="columns">
                                <th width="2%">S/N</th>
                                <th>Full Name <br> (surname last)</th>
                                <th>Gender</th>
                                <th>Matriculation number <br> / Phone number</th>
                                <th>Year of admission</th>
                                <th>Email Address</th>
                            </template>

                            <template
                                slot-scope="{row, index}"
                            >
                                <td class="budget">
                                    {{ pagination.from+index+1}}
                                </td>
                                <td class="text-capitalize">
                                    {{ getFullName(row) }}
                                </td>
                                <td class="text-capitalize">{{ row.gender ? row.gender : 'N/A' }}</td>
                                <td>
                                    {{ row.matric_no ? row.matric_no : 'N/A' }} <br>
                                    <badge type="primary">
                                        {{ row.phone ? row.phone : 'N/A' }}
                                    </badge>
                                </td>
                                <td>{{ row.year ? row.year : 'N/A'  }}</td>
                                <td>{{ row.email ? row.email : 'N/A'  }}</td>
                            </template>

                        </base-table>
                    </div>

                    <div
                        class="card-footer d-flex justify-content-end bg-transparent"
                        v-if="hasPagination"
                        @click="paginate"
                    >
                        <base-pagination
                            :total="pagination.total"
                            align="center"
                            v-model="pagination.number"
                            :perPage="pagination.perPage"

                        >
                        </base-pagination>
                    </div>

                </div>
            </div>

            <div class="col-lg-12 text-center" v-if="students && students.length == 0">
                <base-alert type="warning">
                    <span class="alert-inner--text">
                        <h2 class="text-white">No Students added yet!!!</h2>
                    </span>
                </base-alert>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'IndexingStudents',
        props: ["students", "pagination", "hasPagination", "paginate", "record"],
        filters: {
            getDateFormat(value) {
                return moment(value).format('MMM, YYYY');
            }
        },
        methods: {
            getFullName(record) {
                if(record) {
                    let name = record.first_name;
                    name += record.other_names ? ' ' + record.other_names : '';
                    name += ' ' + record.surname;
                    return name.toLowerCase();
                } else {
                    return 'N/A';
                }
            }
        }
    }
</script>
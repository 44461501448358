<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/"
                                        class="btn btn-sm btn-primary"
                                    >
                                        <i class="ni ni-tv-2 text-white"></i>
                                        Dashboard
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6 text-center borderLine">
                                <br>
                                <h4 class="text-uppercase height-50 text-danger">
                                        View Previous indexing Applications
                                </h4>
                                <router-link
                                    to="/indexing/previous-indexing-applications"
                                    class="btn btn-lg btn-danger"
                                >
                                    Click Here
                                    <i class="fa fa-arrow-right"></i>
                                </router-link>
                                <br><br><br>
                            </div>

                            <div class="col-sm-6 text-center borderLine">
                                <br>
                                <h4 class="text-uppercase height-50">
                                    Add New Indexing
                                </h4>
                                <router-link
                                    to="/indexing/new-indexing"
                                    class="btn btn-lg btn-primary"
                                >
                                    Click Here
                                    <i class="fa fa-arrow-right"></i>
                                </router-link>
                                <br><br><br>
                            </div>

                            <div class="col-sm-6 text-center borderLine">
                                <br>
                                <h4 class="text-uppercase height-50">
                                    View Approved Indexing List
                                </h4>
                                <router-link
                                    to="/indexing/approved-indexing-records"
                                    class="btn btn-lg btn-success"
                                >
                                    Click Here
                                    <i class="fa fa-arrow-right"></i>
                                </router-link>
                                <br><br><br>
                            </div>

                            <div class="col-sm-6 text-center borderLine">
                                <br>
                                <h4 class="text-uppercase height-50">
                                    Search Student Record
                                </h4>
                                <router-link
                                    to="/indexing/search-indexing-record"
                                    class="btn btn-lg btn-info"
                                >
                                    Click Here
                                    <i class="fa fa-search"></i>
                                </router-link>
                                <br><br><br>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Indexing',
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row" v-if="! isLoadingBlade && school">

                <div class="col-xl-12 order-xl-2 mb-5 mb-xl-0">
                    <div class="card card-profile shadow">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 order-lg-2">
                                <div class="card-profile-image">
                                    <a href="javascript:void(0)">
                                        <img
                                            :alt="school.name"
                                            :src="schoolFolderUrl+'/photo/'+school.photo"
                                            class="rounded-circle image-size"
                                            @error="replaceByDefault"
                                        >
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                            <div class="d-flex justify-content-between">
                                <router-link
                                    to="/requirements-policy"
                                    class="mr-4 btn btn-sm btn-primary"
                                    >
                                      Requirements
                                    </router-link>
                                  <base-button
                                    size="sm" type="default"
                                    class="float-right text-capitalize"
                                  >
                                      {{ school.type }} Training
                                  </base-button>
                            </div>
                        </div>
                        <div class="card-body pt-0 pt-md-4">
                            <div class="row">
                                <div class="col">
                                    <div class="card-profile-stats d-flex justify-content-center mt-md-5">

                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <input
                                    type="file"
                                    ref="photo"
                                    style="display: none"
                                    @change="onFileSelected"
                                >
                                <h3>
                                    <base-progress
                                        v-if="loadPercent.show"
                                        :type="loadPercent.type"
                                        :height="10"
                                        :value="loadPercent.value"
                                        :striped="true"
                                        :animated="loadPercent.animated"
                                    >
                                    </base-progress>
                                    <base-button
                                        size="sm" type="info"
                                        class="mr-4 marginLeft23"
                                        v-if="! loadPercent.show"
                                        @click="$refs.photo.click()"
                                    >
                                        <i class="fas fa-pen"></i>
                                        Click to change Logo
                                    </base-button>
                                </h3>
                                <h3 class="text-capitalize">
                                    {{school.name}}
                                </h3>
                                <span class="text-capitalize">
                                    {{school.location}}
                                </span>
                                <div class="h5 mt-4">
                                    <badge size="sm"
                                        :type="school.approved_status == 'approved' ? 'success' : 'warning'"
                                      >
                                        {{ school.approved_status }}
                                      </badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br><br><br><br><br><br>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">

                    <card shadow type="secondary">
                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>
                    </card>

                    <card shadow type="secondary" v-if="! isLoadingBlade">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Update Account Information</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                                </div>
                            </div>
                        </div>

                        <form @submit.prevent="saveProfileData">
                            <h6 class="heading-small text-muted mb-4">User information</h6>

                            <div class="pl-lg-4">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <base-input alternative=""
                                            label="School's Name"
                                            placeholder="School's Name"
                                            input-classes="form-control-alternative text-capitalize"
                                            v-model="profile.name"
                                            required
                                            readonly
                                        />
                                    </div>

                                    <div class="col-lg-4">
                                        <base-input alternative=""
                                            label="School's Email Address"
                                            placeholder="School's Email Address"
                                            input-classes="form-control-alternative"
                                            v-model="profile.email"
                                            required
                                            :readonly="lock"
                                        />
                                    </div>

                                    <div class="col-lg-4">
                                        <base-input alternative=""
                                            label="School's Phone Number"
                                            placeholder="Phone Number"
                                            input-classes="form-control-alternative"
                                            required
                                        >
                                        <!-- v-model="profile.phone"  -->
                                            <VuePhoneNumberInput
                                                v-model="tel.phone"
                                                @update="updatePhoneNumber"
                                                :default-country-code="tel.code"
                                                :no-example="true"
                                                :clearable="true"
                                            />
                                        </base-input>
                                    </div>

                                    <div class="col-lg-12">
                                        <base-input alternative=""
                                            label="Location of School"
                                            required
                                        >
                                            <textarea
                                                rows="2"
                                                class="form-control form-control-alternative"
                                                placeholder="Enter Location"
                                                v-model="profile.location"
                                                :readonly="lock"
                                            ></textarea>
                                        </base-input>
                                    </div>

                                </div>
                            </div>

                            <base-alert :type="errorType" dismissible v-if='showErrorStatus'>
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </base-alert>

                            <hr>

                            <div class="pl-lg-4">
                                <base-button type="success" @click="saveProfileData()">
                                    <i class="fa fa-save" @if='! isloading'></i>
                                    {{isloading ? 'Please Wait' : 'Save Changes'}}
                                </base-button>
                            </div>
                        </form>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // get user's details from store
    import { mapGetters, mapActions } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            VuePhoneNumberInput
        },
        name: 'user-profile',
        data() {
            return {
                profile: {
                    name: null,
                    email: null,
                    phone: null,
                    phone_code: null,
                    location: null,
                    id: null
                },
                savedEvent: null,
                showUploadButton: false,
                photo: {
                    id: null,
                    ext: null,
                    file: null
                },
                loadPercent: {
                    type: 'warning',
                    value: 10,
                    animated: true,
                    show: false
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                isloading: false,
                tel: {
                    phone: null,
                    code: 'NG'
                }
            }
        },
        computed: {
            ...mapGetters(['school', 'loadingBladeUrl', 'lock', 'blankImageUrl', 'schoolFolderUrl'])
        },
        methods: {
            ...mapActions(['uploadUserPhoto', 'fetchSchoolProfile', 'updatePhotoName', 'updateSchoolData']),
            loadPhoneNumber(school) {// update phone no
                let data = school.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.profile.phone = event.formattedNumber;
                    this.profile.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            saveProfileData() {
                this.isloading = true;
                this.preloader();// show loading
                let data = { profile_data : this.profile , id : this.profile.id };
                this.updateSchoolData(data).then((res) => {
                    this.loadProfile();
                    this.isloading = false;
                    if(res.status) {
                        swal.fire("Profile Updated!", res.msg, "success");
                    } else {
                        let msg = "Error occurred while updating, reload page";
                        swal.fire("Error Ocurred!", msg, "warning");
                    }
                }).
                catch(() => {
                    this.isloading = false;
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                });
            },
            loadProfile() {
                this.fetchSchoolProfile().then((res) => {
                    this.profile = {
                        name: res.name,
                        email: res.email,
                        phone: res.phone,
                        phone_code: res.phone_code,
                        location: res.location,
                        id: res.id
                    };
                    this.loadPhoneNumber(res);
                    this.isLoadingBlade = false;

                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                });
            },
            replaceByDefault(e) {
                return e.target.src = this.blankImageUrl;
            },
            makeUploadFieldsEmpty() {
                this.photo.id = null;
                this.photo.ext = null;
                this.photo.file = null;
            },
            onFileSelected(event) {
                this.savedEvent = null;
                this.makeUploadFieldsEmpty();
                this.savedEvent = event;
                let status =  this.validateFile(event.target.files[0]);
                return status ? this.uploadPhoto() : false;
            },
            uploadPhoto() {
                let event = this.savedEvent;// get store file event
                var fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = (event) => {// convert file to binary
                    this.photo.file = event.target.result;
                    let img = new Image();
                    img.src = window.URL.createObjectURL(this.savedEvent.target.files[0]);
                    img.onload = () => {
                        var height = img.height;
                        var width = img.width;
                        if ((width <= 500) && (height <= 500)) {
                            this.makeUploadApiCall();// upload photo
                        } else {
                            let msg = "Width and Height must not exceed 500 * 500 pixels";
                            swal.fire("Invalid Width/Height!", msg, "warning");
                            return false;
                        }
                    }
                }
            },
            makeUploadApiCall() {
                this.photo.id = this.$store.state.user.school.id;// get user id
                this.loadPercent.value = 10;
                this.loadPercent.show = true;
                this.uploadUserPhoto(this.photo).then((res) => {
                    this.uploadPercent(this.$store.state.user.uploadProgress);// show load progress
                    var self = this;
                    self.makeUploadFieldsEmpty();
                    self.savedEvent = null;
                    if(res.status) {
                        self.updatePhotoName(res.file_name);// update file name
                        setTimeout(function() {
                            self.loadPercent.show = false;
                        }, 3000);
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while uploading, try again", "warning");
                        setTimeout(function() {
                            self.loadPercent.show = false;
                        }, 3000);
                    }
                }).
                catch(err => {
                    var self = this;
                    self.makeUploadFieldsEmpty();
                    self.savedEvent = null;
                    swal.fire(err.message+"!", "Reduce file size and try again...", "warning");
                    self.loadPercent.show = false;
                });
                this.fetchSchoolProfile();// refresh page
            },
            uploadPercent(percent) {// update upload progress percent
                this.loadPercent.value = percent;
                this.loadPercent.type = percent > 60 ? 'success' : 'warning';
            },
            validateFile(file) {
                if(!file) { this.savedEvent = null; }// update seleted file
                const validFileTypes = ['jpg', 'jpeg', 'JPEG', 'JPG', 'png', 'PNG'];// valid image format array
                var fileExt = file.type.split('/').pop();// get selected file ext
                this.photo.ext = fileExt;
                var validExt = validFileTypes.includes(fileExt);
                let fileSize = file.size;// get selected file size
                if(validExt && fileSize <= 1000000) {// for valid file and size
                    return true;
                } else if(! validExt) {// invalid file
                    this.savedEvent = null;
                    swal.fire("Invalid File!", "Only JPG/PNG files are accepted", "warning");
                    return false;
                } else if(fileSize > 1000000) {// big file
                    this.savedEvent = null;
                    swal.fire("File is too large!", "File should be less than 1MB", "warning");
                    return false;
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadProfile();// get profile
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 180px;
    }
</style>

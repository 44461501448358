<template>
    <div class="row align-items-center">

        <div class="col-lg-12 text-center">
            <h2 class="text-uppercase">
                Edit <em class="text-danger">{{ fullName }}</em>'s Details
            </h2>
        </div>
        
        <div class="col-lg-12">
            <hr class="my-4"/>
        </div>

        <div class="col-lg-3">
            <base-input alternative=""
                label="Department"
                required
            >
                <select
                    class="form-control form-control-alternative"
                    v-model="record.department"
                >
                    <option :value="null" disabled selected>--- Select One ---</option>
                    <option
                        v-for="department in departments"
                        :value="department.id"
                        :key="department.id"
                    >
                        {{department.name}}
                    </option>
                </select>
            </base-input>
        </div>

        <!-- <div class="col-lg-3">
            <base-input alternative=""
                label="File Number"
                placeholder="Enter File Number"
                input-classes="form-control-alternative"
                v-model="record.file_no"
                type="number"
                required
            />
        </div> -->

        <div class="col-lg-3">
            <base-input alternative=""
                label="Surname"
                placeholder="Enter Surname"
                input-classes="form-control-alternative"
                v-model="record.surname"
                required
            />
        </div>

        <div class="col-lg-3">
            <base-input alternative=""
                label="First Name"
                placeholder="Enter First Name"
                input-classes="form-control-alternative"
                v-model="record.first_name"
                required
            />
        </div>

        <div class="col-lg-3">
            <base-input alternative=""
                label="Other Name"
                placeholder="Enter Other Name"
                input-classes="form-control-alternative"
                v-model="record.other_names"
            />
        </div>

        <div class="col-lg-4">
            <base-input alternative=""
                label="Previous Name"
                placeholder="Enter Previous Name"
                input-classes="form-control-alternative"
                v-model="record.previous_name"
            />
        </div>                

        <div class="col-lg-4">
            <base-input alternative=""
                label="Folio Number (Numbers Only)"
                required
            >
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                    <div class="input-group-text" style="background: #e9ecef;">
                        MDCN/R/
                    </div>
                    </div>
                    <input 
                        type="number" 
                        class="form-control" 
                        placeholder="Enter Folio Number"
                        v-model="record.folio_number"
                        style="padding-left: 5px;"
                    />
                </div>
            </base-input>
        </div>
        

        <div class="col-lg-4">
            <base-input alternative=""
                label="MDCN Full Registration Number"
                placeholder="Enter Full Registration Number"
                input-classes="form-control-alternative"
                v-model="record.full_registration_number"
                type="number"
                required
            />
        </div>

        <div class="col-lg-4">
            <base-input alternative=""
                label="Registered AQ Numbers (seperate with commas)"
                placeholder="Enter Additional Qualification Numbers"
                input-classes="form-control-alternative"
                v-model="record.registered_aq_numbers"
            />
        </div>

        <div class="col-lg-4">
            <base-input alternative=""
                label="Rank in the college"
                placeholder="Enter Rank"
                input-classes="form-control-alternative"
                v-model="record.rank"
                required
            />
        </div>

        <div class="col-lg-4">
            <base-input alternative=""
                label="Position in the college"
                placeholder="Enter Position"
                input-classes="form-control-alternative"
                v-model="record.position"
                required
            />
        </div>

        <div class="col-lg-12">
            <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>
        </div>
        <div class="col-lg-12"></div>

        <div class="col-lg-12">
            <base-button
                type="success"
                @click="saveRecord()"
            >
                <i class="fa fa-save"></i>
                {{isloading ? 'Please Wait...' : 'Update Now' }}
            </base-button>

            <div class="right">
                <base-button
                    type="danger"
                    @click="displayEditPage(false)"
                >
                    <i class="fa fa-times"></i>
                    Cancel Edit
                </base-button>
            </div>
        </div>

    </div>
</template>

<script>
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'EditBulkConsultant',
        props: ["departments", "consultant", "updateConsultant", "displayEditPage"],
        data() {
            return {
                record: {
                    department: null,
                    surname: null,
                    first_name: null,
                    other_names: null,
                    previous_name: null,
                    folio_number: null,
                    full_registration_number: null,
                    registered_aq_numbers: null,
                    rank: null,
                    position: null
                },
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
            }
        },
        computed: {
            fullName() {// get full name
                if(this.record) {
                    let name = this.record.first_name;
                    name += this.record.other_names ? ' ' + this.record.other_names : '';
                    name += ' ' + this.record.surname;
                    return name;
                } else {
                    return '';
                }
            },
        },
        methods: {
            saveRecord() {
                let status = this.validatedInputs();// validate inputs
                let check = status ? this.updateConsultant(this.record) : false;// update record
                let msg = check ? 'Staff was updated successfully' : 'An error occurred, try again';
                if(check) {
                    swal.fire("Staff Updated!", msg , "success");
                    this.displayEditPage(false);
                } else {
                    swal.fire("Error Occurred!", msg , "warning");
                }                
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { department: null, surname: null, first_name: null, folio_number: null, 
                    full_registration_number: null,rank: null, position: null };
                appRecord.position = data.position == null ? this.showInuptError('Position') : data.position;
                appRecord.rank = data.rank == null ? this.showInuptError('Rank') : data.rank;
                appRecord.full_registration_number = data.full_registration_number == null 
                                    ? this.showInuptError('Full Registration Number') : data.full_registration_number;
                appRecord.folio_number  = data.folio_number == null ? this.showInuptError('Folio Number') : data.folio_number;
                appRecord.first_name = data.first_name == null ? this.showInuptError('First Name') : data.first_name;
                appRecord.surname = data.surname == null ? this.showInuptError('Surname') : data.surname;                
                appRecord.department  = data.department == null ? this.showInuptError('Department') : data.department;
                return this.isEmpty(appRecord) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            setData() {
                this.emptyRecords();
                this.record = this.consultant;
            },
            emptyRecords() {
                return this.record = {
                    department: null,
                    surname: null,
                    first_name: null,
                    other_names: null,
                    previous_name: null,
                    folio_number: null,
                    full_registration_number: null,
                    registered_aq_numbers: null,
                    rank: null,
                    position: null
                };
            }
        },
        created() {
            this.setData();
        }
    }
</script>
<template>
    <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
            <div class="card bg-secondary shadow border-0">
                <div class="card-body px-lg-5 py-lg-5">
                    <div class="text-center text-danger mb-4">
                        <span>
                            Note: For email address (Gmail is preferrable),
                            kindly enter valid credentials below
                            <br><br>
                            NB: A School offering both programs needs to create a separate account. 
                            I.e. Account for Medical training and Account for Dental training.
                        </span>
                    </div>

                    <base-alert :type="type" v-if="showMsgBlade">
                        <span class="alert-inner--icon">
                            <i class="ni ni-sound-wave"></i>
                        </span>&nbsp;
                        <span class="alert-inner--text" v-text="msg"></span>
                    </base-alert>

                    <form role="form" @submit.prevent="createAccount">

                        <base-input class="input-group-alternative mb-3"
                                    placeholder="Schools"
                        >
                            <autocomplete
                                input-class="form-control form-control-alternative"
                                placeholder="Type to search for Schools"
                                showNoResults
                                :source="schools"
                                v-model="model.world_school_id"
                                @clear="clearSchool"
                                :results-display="formattedDisplay"
                            >
                            </autocomplete>
                        </base-input>

                        <base-input class="input-group-alternative mb-3"
                                    placeholder="Training Type"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="model.type"
                            >
                                <option :value="null" selected disabled>
                                    --- Select Training Type ---
                                </option>
                                <option value="medical">Medical Training</option>
                                <option value="dental">Dental Training</option>
                            </select>
                        </base-input>

                        <base-input class="input-group-alternative mb-3"
                                    placeholder="Email Address"
                                    addon-left-icon="ni ni-email-83"
                                    v-model="model.email">
                        </base-input>

                        <base-input class="input-group-alternative mb-3"
                                    placeholder="Password"
                                    addon-left-icon="ni ni-key-25"
                                    v-model="model.password"
                                    type="password"
                                    >
                        </base-input>

                        <div class="row my-4">
                            <div class="col-12">
                                <base-checkbox class="custom-control-alternative">
                                    <span class="text-muted">I agree with the
                                        <router-link to="/requirements">Requirements</router-link>
                                    </span>
                                </base-checkbox>
                            </div>
                        </div>

                        <base-alert :type="type" v-if="showMsgBlade">
                            <span class="alert-inner--icon">
                                <i class="ni ni-sound-wave"></i>
                            </span>&nbsp;
                            <span class="alert-inner--text" v-text="msg"></span>
                        </base-alert>

                        <div class="text-center" v-if="! isNotRobot">
                            <vue-recaptcha
                                :sitekey="recaptchaKey"
                                :loadRecaptchaScript="true"
                                @verify="proceedSubmit"
                            ></vue-recaptcha>
                        </div>

                        <div class="text-center">
                            <base-button
                                type="success" class="my-4"
                                nativeType="submit"
                                :disabled="! isNotRobot"
                                v-if="! isLoading"
                            >
                                Create Account
                            </base-button>
                            <button class="btn btn-success" type="button" disabled v-if="isLoading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Please Wait...
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-6">
                    <a href="#/password-reset" class="text-white">
                        <span class="font-weight-bolder">
                            Forgot Password?
                        </span>
                    </a>
                </div>
                <div class="col-6 text-right">
                    <router-link to="/login" class="text-white">
                        <span class="font-weight-bolder">
                            Login Here
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import VueRecaptcha from 'vue-recaptcha';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'Register',
        components: {
            VueRecaptcha,
            Autocomplete
        },
        data() {
            return {
                model: {
                    world_school_id: null,
                    type: null,
                    email: null,
                    password: null
                },
                isLoading: false,
                showMsgBlade: false,
                msg: null,
                type: null,
                isNotRobot: false,
                schools: []
            }
        },
        computed: {
            ...mapGetters(['recaptchaKey'])
        },
        methods: {
            ...mapActions(['getSchools', 'createSignup']),
            clearSchool() {
                return this.model.world_school_id = null;
            },
            formattedDisplay (result) {
                return result.school_name + ' (' + result.city + ', Nigeria)';
            },
            loadSchools() {// load teaching hospitals
                this.preloader(true);
                this.isloading = true;
                this.getSchools().then((res) => {
                    this.isloading = false;
                    this.schools = res.world_schools;
                    swal.close();
                }).
                catch(() => {
                    this.isloading = false;
                    let message = 'An error occurred while fetching data, try again';
                    swal.fire('Network Failure!', message, 'warning');
                });
            },
            createAccount() {// create account
                this.isLoading = true;
                let status = this.validatedInputs();
                if(status && this.isNotRobot) {
                    let validEmail = this.validEmail(this.model.email);
                    if(validEmail) {
                        this.preloader(); // show loader
                        this.isLoading = false;
                        this.createSignup(this.model).then((res) => {
                            this.isloading = false;
                            if(res.status) {
                                swal.fire("Successful!", res.msg, "success");
                                setTimeout(() => { window.location.href = '/login'; }, 6000);
                            } else {
                                swal.fire("Error Occurred!", res.msg, "warning");
                            }
                        }).
                        catch(() => {
                            this.isloading = false;
                            let message = 'An error occurred while creating account, try again';
                            swal.fire('Network Failure!', message, 'warning');
                        });
                    } else {
                        this.isLoading = false;
                        this.showInuptError('Email Address');
                    }
                } else {
                    this.isLoading = false;
                }
            },
            validatedInputs() {
                let data = this.model;
                let record = { world_school_id: null, type: null, email: null, password: null };
                record.password  = data.password == null ? this.showInuptError('Password') : data.password;
                record.email  = data.email == null ? this.showInuptError('Email Address') : data.email;
                record.type = data.type == null ? this.showInuptError('Training Type') : data.type;
                record.world_school_id  = data.world_school_id == null ? this.showInuptError('School') : data.world_school_id;
                return this.isEmpty(record) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            validEmail(email) {// eslint-disable-next-line
                var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return regEmail.test(email);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showMsgBlade = true
                this.type = 'warning'
                this.msg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showMsgBlade = false; }, 5000);
            },
            proceedSubmit(res) {
                if(res) {
                    return setTimeout(() => {
                        this.isNotRobot = ! this.isNotRobot;
                    }, 1000);
                } else {
                    swal.fire("Error Occurred", "An error occurred while verifying, kindly try again", "warning");
                }
            },
            preloader(status = false) {
                return  swal.fire({
                    title: status ? 'Fetching Data...' : 'Creating account...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadSchools();
        }
    }
</script>

<style>
    .bg-default {
        background-color: #f5365c !important;
    }
    .bg-gradient-danger {
        background: linear-gradient(100deg, #f5365c 0, #96e7c4 250%) !important
         /* linear-gradient(87deg, #f7fafc 0, #96e7c4 70%) !important; */
    }
    .fill-default {
        fill: #f5365c !important;
    }
    .navbar-horizontal .navbar-brand img {
        height: 110px;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: rgb(50, 50, 93);
    }
</style>

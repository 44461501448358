<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <AccreditationForm
            :accredit_type="code"
            v-if="((code == 'accreditation') || (code == 're-accreditation'))"
        />

        <AdvisoryForm
            v-if="code == 'advisory'"
        />

    </div>
</template>

<script>
    import AccreditationForm from './AccreditationForm.vue';
    import AdvisoryForm from './AdvisoryForm.vue';

    export default {
        name: 'NewApplicationForm',
        components: {
            AccreditationForm,
            AdvisoryForm
        },
        data() {
            return {
                code: this.$route.params.code
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <h3 class="text-uppercase">Facilities Information</h3>

            <em class="mb-4 text-danger">
                Kindly add facilities below...
            </em>

            <hr class="my-4"/>

            <!-- import institution details template here -->
            <FacilityRecords
                :consultantRecords="consultantRecords"
                :showAddRecord="showAddRecord"
                v-on:editRecord="editRecord"
                v-on:deleteRecord="deleteRecord"
                :pagination="pagination"
                :hasPagination="hasPagination"
                :paginate="paginate"
            />

            <hr class="my-4" v-if="hasRecords && ! showAddRecord"/>

            <div class="pl-lg-4" v-if="hasRecords && ! showAddRecord">
                <base-button
                    type="primary"
                    @click="displayAddPage()"
                >
                    <i class="fa fa-plus"></i>
                    Add New Record
                </base-button>
            </div>


            <div class="row align-items-center" v-if="! hasRecords || showAddRecord">

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Department"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.posting_department_id"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option
                                v-for="department in departments"
                                :value="department.id"
                                :key="department.id"
                            >
                                {{department.name}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Name of Facility"
                        placeholder="Enter Facility's Name"
                        input-classes="form-control-alternative"
                        v-model="record.name"
                        required
                    />
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Facility's Details"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Facility's Details"
                            v-model="record.details"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </base-alert>
                </div>
                <div class="col-lg-12"></div>

                <div class="col-lg-12">
                    <base-button
                        type="success"
                        @click="saveDoctorRecord()"
                    >
                        <i class="fa fa-save"></i>
                        {{isloading ? 'Please Wait...' : updateButton }}
                    </base-button>

                    <div class="right" v-if="hasRecords">
                        <base-button
                            type="danger"
                            @click="displayAddPage()"
                        >
                            <i class="fa fa-times"></i>
                            Cancel
                        </base-button>
                    </div>
                </div>

            </div>

            <hr class="my-4"/>

            <div class="pl-lg-4">
                <base-button
                    type="danger"
                    @click="showPreviousPage()"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous
                </base-button>
                <div class="right">
                    <base-button
                        type="success"
                        @click="showNextPage()"
                        :disabled="! hasRecords"
                    >
                        Preview Application
                        <i class="fa fa-arrow-right"></i>
                    </base-button>
                </div>
            </div>


        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import FacilityRecords from './FacilityRecords/FacilityRecords';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            FacilityRecords
        },
        name: 'FacilityData',
        props: ['loadApplication', 'application', 'id', 'displayStaffData', 'displaySummary', 'dept'],
        data() {
            return {
                consultantRecords: [],
                paginatedItems: [],
                record: {
                    posting_department_id: null,
                    name: null,
                    details: null
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                showAddRecord: false,
                config: {
                    allowInput: true,
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            hasRecords() {
                return this.consultantRecords && this.consultantRecords.length > 0;
            },
            updateButton() {
                return (this.record.id ? 'Update' : 'Add') + ' Record';
            },
            departments() {
                let items = [];
                let type = this.application && this.application.training_school
                                ? this.application.training_school.type : null;
                for(let record of this.dept) {
                    if(record.type == type) {
                        items.push(record);
                    }
                }
                return items;
            },
        },
        methods: {
            ...mapActions(['updateSchoolData', 'deleteSchoolData']),
            displayAddPage() {
                this.emptyRecords();
                return this.showAddRecord = ! this.showAddRecord;
            },
            editRecord(data) {// edit record
                if(data) {
                    this.record = {
                        posting_department_id: data.posting_department_id,
                        name: data.name,
                        details: data.details,
                        id: data.id
                    };
                    this.showAddRecord = true;
                }
            },
            loadFacilityRecords() {
                this.emptyRecords();
                this.isLoadingBlade = false;
                if(this.id) {
                    this.setData(this.application);
                } else {
                    swal.fire("Session Expired!", "Session timeout, try again...", "warning");
                    window.location.href = '/';
                }
            },
            setData(application) {// set paginated function
                if(application && application.training_school) {
                    let res = application.training_school.facilities;
                    this.consultantRecords = [];
                    this.paginatedItems = res;
                    this.pagination.total = this.paginatedItems.length; // get items total
                    let perPage = this.pagination.perPage; // get per page count
                    if(this.paginatedItems.length > perPage) {// paginate
                        let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                        for (let index = 1; index <= numberOfPages; index++) {
                            this.consultantRecords.push(this.paginatedItems[index]);
                        }
                    } else {
                        this.consultantRecords = this.paginatedItems;
                    }
                }
            },
            paginate() {// paginate function
                this.consultantRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.consultantRecords = this.paginatedItems.slice(from, to);
            },
            showPreviousPage() {// go to next page
                return this.displayStaffData(true);
            },
            showNextPage() {// go to next page
                return this.displaySummary(true);
            },
            saveDoctorRecord() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save
                    this.preloader();// show loading
                    this.record.training_school_id = this.application.training_school.id;// add school id
                    let record = { facility_data : this.record , id : this.id };
                    this.updateSchoolData(record).then((res) => {
                        this.loadApplication();
                        this.isloading = false;
                        if(res.status) {
                            this.showErrorStatus = false;
                            this.showAddRecord = false;
                            swal.fire("Record Added!", res.msg , "success");
                            this.loadFacilityRecords();// load appointments
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { posting_department_id: null, name: null, details: null };
                appRecord.details = data.details == null ? this.showInuptError('Facility\'s Details') : data.details;
                appRecord.name = data.name == null ? this.showInuptError('Name of Facility') : data.name;
                appRecord.posting_department_id = data.posting_department_id == null ?
                                                    this.showInuptError('Department') : data.posting_department_id;
                return this.isEmpty(appRecord) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyRecords() {
                return this.record = {
                    posting_department_id: null,
                    name: null,
                    details: null
                };
            },
            deleteRecord(id) {// delete examination
                this.preloader();// show loading
                let data = { facility_data : true , id : id };
                this.deleteSchoolData(data).then((res) => {
                    this.loadApplication();
                    if(res.status) {
                        swal.fire("Record Deleted!", res.msg , "success");
                        this.loadFacilityRecords(); // load appointments
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadFacilityRecords(); // load appointments
                    }
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                    this.loadFacilityRecords(); // load appointments
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadFacilityRecords();// load appointments
        },
    }
</script>

<style>
.right {
    float: right;
}
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>
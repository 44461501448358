<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <!-- <span class="text-danger">
                                        Kindly register for Assessment Exam below by clicking appropriate button...
                                    </span> -->
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/logout"
                                        class="btn btn-danger"
                                        v-if="! status"
                                    >
                                        <i class="ni ni-user-run text-white"></i>
                                        Logout
                                    </router-link>

                                    <router-link
                                        to="/"
                                        class="btn btn-sm btn-primary"
                                        v-if="status"
                                    >
                                        <i class="ni ni-tv-2 text-white"></i>
                                        Dashboard
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="! isLoadingBlade && showPolicy">
                            <div class="col-lg-12"
                                v-html="policy.content"
                                v-if="policy"
                                id="printMe"
                            ></div>

                            <div class="col-lg-12">
                                <hr>
                                <router-link
                                    to="/logout"
                                    class="btn btn-danger"
                                    v-if="! status"
                                >
                                    <i class="fa fa-times"></i>
                                    I Disagree
                                </router-link>

                                <button 
                                    @click="print"
                                    class="btn btn-info"
                                >
                                    <i class="fa fa-print"></i>
                                    Print Now
                                </button>

                                <router-link
                                    to="/"
                                    class="btn btn-danger"
                                    v-if="status"
                                >
                                    <i class="fa fa-times"></i>
                                    I Disagree
                                </router-link>

                                <base-button
                                    type="success"
                                    class="right"
                                    :disabled="isLoadingBlade"
                                    @click="hidePolicy(false)"
                                >
                                    <i class="fa fa-check"></i>
                                    I Agree
                                </base-button>
                            </div>
                        </div>

                        <div class="row" v-if="isLoadingBlade">
                            <div class="col-lg-12">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>
                        </div>

                        <div v-if="! showPolicy">
                            <div class="row" v-if='! showErrorStatus && ! isLoadingBlade'>
                                <div class="col-lg-12 text-center">
                                    <h2 class="text-uppercase height-50">
                                        Accreditation / Re-Accreditation Application <br> 
                                        <small class="text-danger" style="font-size: 60%;">
                                            This is for Institutions applying for Accreditation (for the first
                                            time) or for those applying for Re-Accreditation (i.e Renewal)
                                        </small>
                                    </h2><br>
                                </div>
                                <div class="col-sm-6 text-center borderLine">
                                    <br>
                                    <h4 class="text-uppercase height-50 text-danger">
                                        I do not have RRR and I have not made payment
                                    </h4>
                                    <router-link
                                        to="/accreditation-generate"
                                        class="btn btn-sm btn-primary"
                                    >
                                        Click &amp; Continue
                                        <i class="fa fa-arrow-right"></i>
                                    </router-link>
                                    <br><br><br>
                                </div>

                                <div class="col-sm-6 text-center borderLine">
                                    <br>
                                    <h4 class="text-uppercase height-50">
                                        I have RRR and have made payment, I want to continue
                                        my registration
                                    </h4>
                                    <router-link
                                        to="/accreditation/check-payment"
                                        class="btn btn-sm btn-success"
                                    >
                                        Click &amp; Continue
                                        <i class="fa fa-arrow-right"></i>
                                    </router-link>
                                    <br><br><br>
                                </div>
                            </div>

                            <div class="row" v-if='! showErrorStatus && ! isLoadingBlade'>
                                <div class="col-lg-12 text-center">
                                    <hr>
                                    <h2 class="text-uppercase height-50">
                                        Advisory Visit Application <br>
                                        <small class="text-danger" style="font-size: 60%;">
                                            This is for Institutions applying for Advisory Visitation by the Council
                                        </small>
                                    </h2><br>
                                </div>
                                <div class="col-sm-6 text-center borderLine">
                                    <br>
                                    <h4 class="text-uppercase height-50 text-danger">
                                        I do not have RRR and I have not made payment
                                    </h4>
                                    <router-link
                                        to="/advisory-generate"
                                        class="btn btn-sm btn-primary"
                                    >
                                        Click &amp; Continue
                                        <i class="fa fa-arrow-right"></i>
                                    </router-link>
                                    <br><br><br>
                                </div>

                                <div class="col-sm-6 text-center borderLine">
                                    <br>
                                    <h4 class="text-uppercase height-50">
                                        I have RRR and have made payment, I want to continue
                                        my registration
                                    </h4>
                                    <router-link
                                        to="/advisory/check-payment"
                                        class="btn btn-sm btn-success"
                                    >
                                        Click &amp; Continue
                                        <i class="fa fa-arrow-right"></i>
                                    </router-link>
                                    <br><br><br>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                                    <base-alert :type="errorType">
                                        <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                        <span class="alert-inner--text">
                                            <h2 class="text-white">{{errorMsg}}</h2>
                                        </span>
                                    </base-alert>
                                </div>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'Verification',
        props: ['type'],
        data() {
            return {
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                showPolicy: true,
                policy: null,
                path: this.$route.path
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            status() {// check for re-accreditation
                return this.type ? true : false;
            }
        },
        methods: {
            ...mapActions(['getSiteParameter', 'fetchSchoolProfile']),
            loadProfile() {
                this.fetchSchoolProfile().then((res) => {
                    this.isLoadingBlade = false;
                    if(res && (res.status == 'enable') && (this.path == "/verification-payment")) {
                        return window.location.href = '/';
                    } else {
                        return this.getParameter();
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            getParameter() {// get Parameter
                let name = 'school-policy';
                this.getSiteParameter({'name': name}).then((res) => {
                    this.isLoadingBlade = false;
                    if(res) {
                        this.policy = res;
                    } else {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, kindly reload the page...";
                        this.errorType = 'danger';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            hidePolicy(value) {
                this.showPolicy = value;
            },
            async print () {
                await this.$htmlToPaper('printMe');
            }
        },
        created() {
            this.loadProfile();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
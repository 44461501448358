<template>
    <div class="row">
        <div class="col-lg-12 text-center" v-if="! showEditPage && ! showQualPage">
            <h2 class="text-uppercase">
                Bulk upload of Academic Staff
            </h2>
            <hr>
        </div>
        <div 
            class="col-lg-12" 
            v-if="currentRecords && (currentRecords.length > 0) && ! showEditPage && ! showQualPage"
        >
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="consultants"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th>Staff Name <br> / Folio No</th>
                            <th>Previous Name</th>
                            <th>Department</th>
                            <th>Registration No <br>/ Add. Qual Nos</th>
                            <th>Rank /<br> Position</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index+1}}
                        </td>
                        <td class="text-capitalize">
                            {{ getFullName(row) }}<br>
                            <badge type="info" class="text-uppercase">
                                {{ row.folio_number ? ('MDCN/R/' + row.folio_number) : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            {{ row.previous_name ? row.previous_name : 'N/A' }}
                        </td>
                        <td>
                            {{ getExactDept(row.department) }}
                        </td>
                        <td>
                            {{ row.full_registration_number ? row.full_registration_number : 'N/A' }}<br>
                            <badge type="success">
                                {{ row.registered_aq_numbers ? row.registered_aq_numbers : 'N/A' }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.rank ? row.rank : 'N/A' }}<br>
                            <badge type="primary">{{ row.position ? row.position : 'N/A' }}</badge>
                        </td>
                        <td>
                            <base-button
                                type="info" size="sm"
                                @click="editRecord(row, pagination.from+index)"
                            >
                                <i class="fa fa-pen"></i> Edit
                            </base-button>
                            <base-button
                                type="primary" size="sm"
                                @click="addQualification(row, pagination.from+index)"
                            >
                                <i class="fa fa-plus"></i> 
                                Qualifications ({{ row.qualifications ? row.qualifications.length : 0}})
                            </base-button>
                            <base-button
                                type="danger" size="sm"
                                @click="deleteRecord(pagination.from+index)"
                            >
                                <i class="fa fa-trash"></i> Remove
                            </base-button>
                        </td>
                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>
            </div>
        </div>

        <div 
            class="col-lg-12 text-center" 
            v-if="currentRecords && (currentRecords.length == 0) && ! showEditPage && ! showQualPage"
        >
            <base-alert type="danger">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    No Record was uploaded, kindly populate the sheet before uploading!!!
                </span>
            </base-alert>

            <base-button
                type="danger"
                @click="$emit('displayUploadPage', false)"
            >
                <i class="fa fa-arrow-left"></i>
                Go Back
            </base-button>
        </div>

        <EditBulkConsultant 
            :consultant="consultant"
            :departments="departments"
            :updateConsultant="updateConsultant"
            :displayEditPage="displayEditPage"
            v-if="showEditPage && ! showQualPage"
        />

        <UpdateQualification 
            :consultant="consultant"
            :displayQualPage="displayQualPage"
            :updateQualification="updateQualification"
            v-if="! showEditPage && showQualPage"
        />

        <div class="col-lg-12"><hr></div>

        <div class="col-lg-12" v-if="! showQualPage && ! showEditPage">
            <base-button
                type="success"
                @click="$emit('uploadStaffNow')"
            >
                <i class="fa fa-upload"></i>
                {{isloading ? 'Please Wait...' : 'Upload Now' }}
            </base-button>

            <div class="right">
                <base-button
                    type="danger"
                    @click="$emit('displayUploadPage', false)"
                >
                    <i class="fa fa-times"></i>
                    Cancel Upload
                </base-button>
            </div>
        </div>


    </div>
</template>

<script>
    import EditBulkConsultant from './EditBulkConsultant.vue';
    import UpdateQualification from './UpdateQualification.vue';

    export default {
        name: 'ConsultantBulkUpload',
        props: ["records", "departments", "isloading"],
        components: {
            EditBulkConsultant,
            UpdateQualification
        },
        data() {
            return {
                consultant: {},
                consultants: [],
                paginatedItems: [],
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                    // perPage: 2 // get per page count
                },
                currentRecords: this.records,
                showEditPage: false,
                showQualPage: false
            }
        },
        computed: {
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            }
        },
        methods: {
            setData() {// set paginated function
                let res = this.currentRecords;
                this.consultants = [];
                this.paginatedItems = res;
                this.pagination.total = this.paginatedItems.length; // get items total
                let perPage = this.pagination.perPage; // get per page count
                if(this.paginatedItems.length > perPage) {// paginate
                    for (let index = 1; index <= perPage; index++) {
                        this.consultants.push(this.paginatedItems[index]);
                    }
                } else {
                    this.consultants = this.paginatedItems;
                }
            },
            paginate() {// paginate function
                this.consultants = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.consultants = this.paginatedItems.slice(from, to);
            },
            updateQualification(record) {// update a consultant Qualification function
                let id = record.id;
                delete record.id;
                return this.currentRecords[id] = record;
            },
            updateConsultant(record) {// update a consultant function
                let id = record.id;
                delete record.id;
                return this.currentRecords[id] = record;
            },
            deleteRecord(index) {// remove record
                this.currentRecords.splice(index, 1);
                return this.setData();
            },
            displayQualPage(status) {// edit page display function
                this.showQualPage = status;
            },
            displayEditPage(status) {// edit page display function
                this.showEditPage = status;
            },
            addQualification(record, index) {// update qualifications
                let qualifications = record.qualifications && record.qualifications.length > 0 
                                        ? record.qualifications : [];
                this.showEditPage = false;
                this.showQualPage = false;
                this.consultant = {};
                this.consultant = record;
                this.consultant.id = index;// add an id
                this.consultant.qualifications = qualifications;// add an id
                this.showQualPage = true;
            },
            editRecord(record, index) {// edit consultant
                this.showEditPage = false;
                this.showQualPage = false;
                this.consultant = {};
                this.consultant = record;
                this.consultant.id = index;// add an id
                this.showEditPage = true;
            },
            getFullName(record) {
                if(record) {
                    let name = record.first_name;
                    name += record.other_names ? ' ' + record.other_names : '';
                    name += ' ' + record.surname;
                    return name;
                } else {
                    return 'N/A';
                }
            },
            getExactDept(record) {// get exact dept
                if(record) {
                    let dept = this.departments.filter(e => e.id == record);
                    return dept && dept.length > 0 ? dept[0].name : 'N/A';
                } else {
                    return 'N/A';
                }
            }
        },
        created() {
            this.setData();
        }
    }
</script>
<template>
    <div>
        <div class="container-fluid mt--7">
            <br><br><br>
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                    <br>
                                    <span class="text-danger" v-html="description"></span>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/verification-payment"
                                        class="btn btn-danger"
                                    >
                                        <i class="fa fa-reply"></i>
                                        Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div v-if="! submitted">
                            <!-- this is hospital's profile template -->
                            <ProfileData
                                :id="application.id"
                                :data="application"
                                :transaction="application.transaction"
                                :loadApplication="loadApplication"
                                :displayConsultantData="displayConsultantData"
                                :accredit_type="accredit_type"
                                v-if="showProfileData && application && application.training_school && ! isLoadingBlade"
                            />

                            <!-- this is hospital's Consultants template -->
                            <ConsultantData
                                :id="application.id"
                                :application="application"
                                :dept="departments"
                                :loadApplication="loadApplication"
                                :displayProfileData="displayProfileData"
                                :displayStaffData="displayStaffData"
                                v-if="showConsultantData && application && application.training_school
                                        && application.training_school.doctors && ! isLoadingBlade"
                            />

                            <!-- this is hospital's Staff template -->
                            <StaffData
                                :id="application.id"
                                :application="application"
                                :loadApplication="loadApplication"
                                :displayConsultantData="displayConsultantData"
                                :displayFacilityData="displayFacilityData"
                                v-if="showStaffData && application && application.training_school
                                        && application.training_school.staff && ! isLoadingBlade"
                            />

                            <!-- this is hospital's facility template -->
                            <FacilityData
                                :id="application.id"
                                :dept="departments"
                                :application="application"
                                :loadApplication="loadApplication"
                                :displayStaffData="displayStaffData"
                                :displaySummary="displaySummary"
                                v-if="showFacilityData && application && application.training_school
                                        && application.training_school.facilities && ! isLoadingBlade"
                            />

                            <!-- this is application summary template -->
                            <VerificationSummary
                                :id="application.id"
                                :application="application"
                                :loadApplication="loadApplication"
                                :displayFacilityData="displayFacilityData"
                                v-if="showSummary && application && ! isLoadingBlade"
                            />

                            <div class="col-lg-12" v-if="isLoadingBlade">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>

                            <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                                <base-alert :type="errorType">
                                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                    <span class="alert-inner--text">
                                        <h2 class="text-white">{{errorMsg}}</h2>
                                    </span>
                                </base-alert>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import ProfileData from '../../../Verification/Application/Accreditation/ProfileData.vue';
    import ConsultantData from '../../../Verification/Application/Accreditation/ConsultantData';
    import StaffData from '../../../Verification/Application/Accreditation/StaffData';
    import FacilityData from '../../../Verification/Application/Accreditation/FacilityData';
    import VerificationSummary from '../../../Verification/Application/Accreditation/VerificationSummary/VerificationSummary';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AccreditationForm',
        props: ['accredit_type'],
        components: {
            ProfileData,
            ConsultantData,
            StaffData,
            FacilityData,
            VerificationSummary
        },
        data() {
            return {
                code: this.$route.params.code,
                application: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                showProfileData: true,
                showConsultantData: false,
                showStaffData: false,
                showFacilityData: false,
                showSummary: false,
                title: null,
                description: null,
                departments: []
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'locations', 'loadingBladeUrl']),
            submitted() {
                return this.hospital && (this.hospital.status == 'submitted' || this.hospital.status == 'approved'
                    || this.hospital.status == 'rejected');
            }
        },
        methods: {
            ...mapActions(['getPendingApplication']),
            loadTitle() {// load title
                this.title = this.replaceString(this.$route.name, this.code);
                this.description = 'Kindly fill the form below with correct information...';
            },
            replaceString(value, code) {
                return value && code ? code+' '+ value.replace('-', ' ') : 'Application Form';
            },
            changeTitle(value, des, status) {// change title
                let description = des ? des : 'Kindly fill the form below with correct information...';
                if(status) {
                    this.title = value;
                    this.description = description;
                } else {
                    this.loadTitle();
                }
            },
            loadApplication() {
                this.isLoadingBlade = true;
                this.application = null;
                let data = { type: this.code };
                this.getPendingApplication(data).then((res) => {
                    this.isLoadingBlade = false;
                    if(res.status) {
                        this.loadTitle();
                        this.application = res.application;
                        this.departments = res.departments;
                        if(! res.application) {
                            return window.location.href = '/';
                        }
                    } else {
                        let msg = 'Payment has not been made, proceed to make payment'
                        swal.fire("Payment Failed!", msg, "warning");
                        setTimeout(function() { window.location.href = '/'; }, 3000);
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            displayProfileData(value) {
                this.showProfileData = value;
                this.showConsultantData = ! value;
                this.showStaffData = ! value;
                this.showSummary = ! value;
                this.showFacilityData = ! value;
            },
            displayConsultantData(value) {
                this.showProfileData = ! value;
                this.showStaffData = ! value;
                this.showFacilityData = ! value;
                this.showSummary = ! value;
                this.showConsultantData = value;
            },
            displayStaffData(value) {
                this.showProfileData = ! value;
                this.showConsultantData = ! value;
                this.showFacilityData = ! value;
                this.showSummary = ! value;
                this.showStaffData = value;
            },
            displayFacilityData(value) {
                this.showProfileData = ! value;
                this.showConsultantData = ! value;
                this.showStaffData = ! value;
                this.showSummary = ! value;
                this.showFacilityData = value;
            },
            displaySummary(value) {
                this.showProfileData = ! value;
                this.showConsultantData = ! value;
                this.showStaffData = ! value;
                this.showFacilityData = ! value;
                this.showSummary = value;
            }
        },
        created() {
            this.loadApplication();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
<template>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-lg-12">
                <hr class="my-4"/>
            </div>

            <div class="col-lg-12" v-if="qualifications && qualifications.length > 0">
                <div class="card shadow">
                    <div class="table-responsive">
                        <base-table
                            class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="qualifications"
                        >
                            <template slot="columns">
                                <th width="2%">S/N</th>
                                <th>Qualification Name</th>
                                <th>Qualification Date</th>
                                <th width="5%">&nbsp;</th>
                            </template>

                            <template
                                slot-scope="{row, index}"
                            >
                            <td class="budget">
                                {{ pagination.from+index+1}}
                            </td>
                            <td class="text-capitalize">
                                {{ row.name }}
                            </td>
                            <td class="text-capitalize">{{ row.qual_date | getDateFormat }}</td>
                            <td>
                                <base-button
                                    type="info" size="sm"
                                    @click="$emit('editRecord', row)"
                                >
                                    <i class="fa fa-pen"></i> Edit
                                </base-button>
                                <base-button
                                    type="danger" size="sm"
                                    @click="$emit('deleteRecord', row.id)"
                                >
                                    <i class="fa fa-trash"></i> Remove
                                </base-button>
                            </td>

                            </template>

                        </base-table>
                    </div>

                    <div
                        class="card-footer d-flex justify-content-end bg-transparent"
                        v-if="hasPagination"
                        @click="paginate"
                    >
                        <base-pagination
                            :total="pagination.total"
                            align="center"
                            v-model="pagination.number"
                            :perPage="pagination.perPage"

                        >
                        </base-pagination>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'QualificationData',
        props: ["records"],
        filters: {
            getDateFormat(value) {
                return moment(value).format('MMM, YYYY');
            }
        },
        data() {
            return {
                qualifications: [],
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                    // perPage: 2 // get per page count
                }
            }
        },
        computed: {
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            }
        },
        methods: {
            setData() {// set paginated function
                let res = this.records;
                this.qualifications = [];
                this.paginatedItems = res;
                this.pagination.total = this.paginatedItems.length; // get items total
                let perPage = this.pagination.perPage; // get per page count
                if(this.paginatedItems.length > perPage) {// paginate
                    for (let index = 1; index <= perPage; index++) {
                        this.qualifications.push(this.paginatedItems[index]);
                    }
                } else {
                    this.qualifications = this.paginatedItems;
                }
            },
            paginate() {// paginate function
                this.qualifications = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.qualifications = this.paginatedItems.slice(from, to);
            },
        },
        created() {
            this.setData();
        }
    }
</script>
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">



                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">My Approved Letters</h3>
                                </div>
                                <!-- <div class="col-4 text-right">
                                    <a href="#!" class="btn btn-sm btn-primary">Settings</a>
                                </div> -->
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <!-- import Letters details template here -->
                        <LetterData
                            :records="records"
                            :pagination="pagination"
                            :hasPagination="hasPagination"
                            :paginate="paginate"
                            v-if="! isLoadingBlade"
                        />

                        <div class="text-center" v-if='showErrorStatus'>
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                    </card>
                </div>

            </div>
        </div>



    </div>
</template>
<script>
    // get user's details from store
    import { mapGetters, mapActions } from 'vuex';
    import LetterData from './LetterData.vue';

    export default {
        components: {
            LetterData
        },
        name: 'MyLetters',
        data() {
            return {
                records: [],
                paginatedItems: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            }
        },
        methods: {
            ...mapActions(['getSchoolLetters']),
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Approved Letters Yet!!!';
            },
            loadUserCertificates() {
                this.getSchoolLetters().then((res) => {
                    this.setPages(res);
                    this.isLoadingBlade = false;
                    this.emptyRecordsMsg();//show empty msg
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.records = [];
                this.paginatedItems = res.applications;
                this.pagination.total = this.paginatedItems.length; // get items total
                let perPage = this.pagination.perPage; // get per page count
                if(this.paginatedItems.length > perPage) {// paginate
                    let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                    for (let index = 1; index <= numberOfPages; index++) {
                        this.records.push(this.paginatedItems[index]);
                    }
                } else {
                    this.records = this.paginatedItems;
                }
            },
            paginate() {// paginate function
                this.records = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.records = this.paginatedItems.slice(from, to);
            },
        },
        created() {
            this.loadUserCertificates();
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 190px;
    }
</style>
